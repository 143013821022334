import React from "react";
import styled from "styled-components/macro";
import _ from "lodash";

import { Grid, Divider as MuiDivider } from "@material-ui/core";

import { spacing } from "@material-ui/system";

import { green, red, grey } from "@material-ui/core/colors";

import Cac from "./Cac";
import ApprovedSalesVsInvestment from "./ApprovedSalesVsInvestment";
import MediaCost from "./MediaCost";
import InvestmentsByChannel from "./InvestmentsByChannel";
import Stats from "./Stats";
import { useSelector } from "react-redux";

const Divider = styled(MuiDivider)(spacing);

const currency = Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

const percent = Intl.NumberFormat("pt-BR", {
  style: "percent",
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

const percentInteger = Intl.NumberFormat("pt-BR", {
  style: "percent",
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

const comparePeriods = (lastPeriod, thisPeriod, invertColor = false) => {
  let numericValue = (thisPeriod - lastPeriod) / lastPeriod;

  if (typeof numericValue !== "number" || !isFinite(numericValue)) {
    return {
      formatted: "-%",
      color: grey[500],
    };
  }

  const plus = numericValue > 0 ? "+" : "";
  const hight = !invertColor ? green[500] : red[500];
  const low = !invertColor ? red[500] : green[500];
  return {
    formatted: plus + percent.format(numericValue),
    color: numericValue > 0 ? hight : low,
  };
};

function Content({ data }) {
  const { loadPeriod } = useSelector((state) => state.periodReducer);

  const { tag } = loadPeriod();

  const approvedSales = currency.format(data.approvedSales.sum);
  const predictApprovedSales = {
    amount: approvedSales,
  };

  const mediaCost = percent.format(data.mediaCost.avg / 100);
  const predictMediaCost = {
    amount: mediaCost,
  };

  if (
    !_.isEmpty(data.openBoletos) &&
    data.openBoletos.sumOfExpectedConversionAmount > 0
  ) {
    // Approved Sales
    const percentage = percentInteger.format(
      data.openBoletos.sumOfExpectedConversionAmount /
        data.openBoletos.sumOfOpenAmount
    );
    const extra = currency.format(
      data.openBoletos.sumOfExpectedConversionAmount
    );
    predictApprovedSales.tooltipText = `Vendas aprovadas (${approvedSales}) + ${percentage} de aprovação de boletos pendentes (${extra})`;
    predictApprovedSales.amount = currency.format(
      data.approvedSales.sum + data.openBoletos.sumOfExpectedConversionAmount
    );

    // Media Cost
    predictMediaCost.amount = percent.format(
      data.paidMedia.sum /
        (data.approvedSales.sum +
          data.openBoletos.sumOfExpectedConversionAmount)
    );
    predictMediaCost.tooltipText = `Com base no valor acrescido na projeção de boletos pendentes. Valor original: ${mediaCost}`;
  }

  const paidMedia = currency.format(data.paidMedia.sum);

  const avgTicket = currency.format(data.avgTicket.avg);

  const compareApprovedSales = comparePeriods(
    data.lastApprovedSales.sum,
    data.approvedSales.sum
  );
  const comparePaidMedia = comparePeriods(
    data.lastPaidMedia.sum,
    data.paidMedia.sum
  );
  const compareMediaCost = comparePeriods(
    data.lastMediaCost.avg / 100,
    data.mediaCost.avg / 100,
    true
  );
  const compareAvgTicket = comparePeriods(
    data.lastAvgTicket.avg,
    data.avgTicket.avg
  );

  return (
    <React.Fragment>
      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Vendas Aprovadas"
            chip={tag}
            percentageText={compareApprovedSales.formatted}
            percentagecolor={compareApprovedSales.color}
            {...predictApprovedSales}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Investimento (Mídia Paga)"
            amount={paidMedia}
            chip={tag}
            percentageText={comparePaidMedia.formatted}
            percentagecolor={comparePaidMedia.color}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Custo de Mídia (%)"
            chip={tag}
            percentageText={compareMediaCost.formatted}
            percentagecolor={compareMediaCost.color}
            {...predictMediaCost}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Ticket Médio"
            amount={avgTicket}
            chip={tag}
            percentageText={compareAvgTicket.formatted}
            percentagecolor={compareAvgTicket.color}
          />
        </Grid>
      </Grid>

      <Grid container spacing={6}>
        <Grid item xs={12} lg={7} xl={8}>
          <Cac cacData={data.cac} />
        </Grid>
        <Grid item xs={12} lg={5} xl={4}>
          <InvestmentsByChannel
            investmentData={data.investment}
            lastInvestmentData={data.lastInvestment}
          />
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12} lg={6}>
          <ApprovedSalesVsInvestment
            salesInvestmentData={data.approvedSalesVsPaidMedia}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <MediaCost mediaData={data.mediaByMonth} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Content;
