import React from "react";
import styled from "styled-components/macro";
import _ from "lodash";

import { Grid, Divider as MuiDivider } from "@material-ui/core";

import { spacing } from "@material-ui/system";

import { useSelector } from "react-redux";
import Stats from "../marketing/Stats";

import { green, red, grey } from "@material-ui/core/colors";

const Divider = styled(MuiDivider)(spacing);

const currency = Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

const percent = Intl.NumberFormat("pt-BR", {
  style: "percent",
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

const comparePeriods = (lastPeriod, thisPeriod, invertColor = false) => {
  let numericValue = (thisPeriod - lastPeriod) / lastPeriod;

  if (typeof numericValue !== "number" || !isFinite(numericValue)) {
    return {
      formatted: "-%",
      color: grey[500],
    };
  }

  const plus = numericValue > 0 ? "+" : "";
  const hight = !invertColor ? green[500] : red[500];
  const low = !invertColor ? red[500] : green[500];

  return {
    percentageText: plus + percent.format(numericValue),
    percentagecolor: numericValue > 0 ? hight : low,
  };
};

function Content({ data }) {
  const { loadPeriod } = useSelector((state) => state.periodReducer);

  const { tag } = loadPeriod();
  return (
    <React.Fragment>
      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="GMV (Vendas)"
            chip={tag}
            amount={currency.format(data.totalApprovedSales.amountReceived)}
            {...comparePeriods(
              data.previousTotalApprovedSales.amountReceived,
              data.totalApprovedSales.amountReceived
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Mídia (Total)"
            chip={tag}
            amount={currency.format(
              data.metaInvestment.amountSpent +
                data.googleInvestment.amountSpent
            )}
            {...comparePeriods(
              data.previousMetaInvestment.amountSpent +
                data.previousGoogleInvestment.amountSpent,
              data.metaInvestment.amountSpent +
                data.googleInvestment.amountSpent
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Mídia (Meta)"
            chip={tag}
            amount={currency.format(data.metaInvestment.amountSpent)}
            {...comparePeriods(
              data.previousMetaInvestment.amountSpent,
              data.metaInvestment.amountSpent
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Mídia (Google)"
            chip={tag}
            amount={currency.format(data.googleInvestment.amountSpent)}
            {...comparePeriods(
              data.previousGoogleInvestment.amountSpent,
              data.googleInvestment.amountSpent
            )}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Content;
