import React from "react";
import styled from "styled-components/macro";
import _ from "lodash";

import { Grid, Divider as MuiDivider } from "@material-ui/core";

import { spacing } from "@material-ui/system";

import { useSelector } from "react-redux";
import Stats from "../marketing/Stats";
import GenericDashTable from "../common/GenericDashTable";

const Divider = styled(MuiDivider)(spacing);

function Content({ data }) {
  const { loadPeriod } = useSelector((state) => state.periodReducer);

  const { tag } = loadPeriod();
  return (
    <React.Fragment>
      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Clientes que fizeram mais de 1 compra"
            chip={tag}
            amount={data.amountCustomersWithMultipleOrders}
          />
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12} lg={5} xl={4}>
          <GenericDashTable
            title="titulo do dash"
            data={data.amountOfOrdersByGender}
            columns={["Gênero", "Quantidade de pedidos"]}
            attributes={["gender", "amount"]}
          />
        </Grid>
        <Grid item xs={12} lg={5} xl={4}>
          <GenericDashTable
            title="titulo do dash"
            data={data.amountOfOrdersByDevice}
            columns={["Dispositivo", "Quantidade de pedidos"]}
            attributes={["device", "amount"]}
          />
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12} lg={5} xl={4}>
          <GenericDashTable
            title="titulo do dash"
            data={data.amountOfOrdersByPaymentMethod}
            columns={["Forma de Pagamento", "Quantidade de pedidos"]}
            attributes={["paymentMethod", "amount"]}
          />
        </Grid>
        <Grid item xs={12} lg={5} xl={4}>
          <GenericDashTable
            title="titulo do dash"
            data={data.amountOfOrdersByAge}
            columns={["Idade", "Quantidade de pedidos"]}
            attributes={["age", "amount"]}
          />
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12} lg={5} xl={4}>
          <GenericDashTable
            title="titulo do dash"
            data={data.amountOfOrdersByPaymentCondition}
            columns={["Condição de Pagamento", "Quantidade de pedidos"]}
            attributes={["paymentCondition", "amount"]}
          />
        </Grid>

        <Grid item xs={12} lg={5} xl={4}>
          <GenericDashTable
            title="titulo do dash"
            data={data.aggregateByState}
            columns={["UF", "Quantidade de pedidos", "Ticket médio"]}
            attributes={["state", "amount", "ticket"]}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Content;
