import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Formik, useFormikContext } from "formik";
import { withRouter } from "react-router-dom";

import { Helmet } from "react-helmet-async";
import styled, { withTheme } from "styled-components/macro";
import {
  Box,
  Grid,
  TextField as MuiTextField,
  Typography as MuiTypography,
  Divider as MuiDivider,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  IconButton,
  Modal,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Alert } from "@material-ui/lab";
import axios from "../../../utils/axios";
import { LOGIN_URL } from "../../../constants/urls";
import { Edit as EditIcon } from "@material-ui/icons";

const TextField = styled(MuiTextField)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

const CreateButton = styled(Button)`
  padding: 10px 16px;
`;

const OverflowXGrid = styled(Grid)`
  overflow-x: scroll;
`;

const ModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: black;
  border: 2px solid #000;
  box-shadow: 24px;
  p: 4;
`;

function intToCurrency(value) {
  return parseFloat(value).toLocaleString("pt-br", {
    style: "currency",
    currency: "BRL",
  });
}

function formatCNPJ(value) {
  return value.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
    "$1.$2.$3/$4-$5"
  );
}

const contractTypeDescription = {
  sale: "Sobre o valor de vendas",
  investment: "Sobre o valor de investimento",
  "sale-percentage": "% (percentual) sobre o valor de vendas",
};

function formatDescription(contractType) {
  return contractTypeDescription[contractType];
}

function formatTax(contractType, tax) {
  return contractType === "sale"
    ? intToCurrency(tax)
    : parseFloat(tax).toLocaleString("pt-br") + "%";
}

const currentDate = new Date();
let periods = [];
for (let i = 0; i < 2; i++) {
  let month;
  let year;
  if (currentDate.getMonth() + i > 12) {
    month = currentDate.getMonth() + i - 12;
    year = currentDate.getFullYear() + 1;
  } else if (currentDate.getMonth() + i === 0) {
    month = 12;
    year = currentDate.getFullYear() - 1;
  } else {
    month = currentDate.getMonth() + i;
    year = currentDate.getFullYear();
  }
  month = month.toString().padStart(2, "0");
  periods.push({
    value: {
      month,
      year,
    },
    text: `${month}/${year}`,
  });
}

function FinancialForm({ location: { state } }) {
  const {
    user: { jwt },
  } = useSelector((state) => state.authReducer);
  const [error, setError] = useState(false);
  const [isSubmitAble, setSubmitAble] = useState(false);
  const [lastPeriod, setLastPeriod] = useState(null);
  const [financeToUpdate, setFinanceToUpdate] = useState({
    referenceTax: 1,
    referenceValue: 2,
    value: 3,
  });

  const FormObserver = () => {
    const { values } = useFormikContext();
    useEffect(() => {
      if (values.period && lastPeriod !== JSON.stringify(values.period)) {
        setLastPeriod(JSON.stringify(values.period));
        values.companiesFinancial = [];
        axios
          .get(
            `${LOGIN_URL}/companies/financial?month=${values.period.month}&year=${values.period.year}`,
            {
              headers: {
                Authorization: `Bearer ${jwt}`,
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              setSubmitAble(true);
              values.companiesFinancial = response.data;
              values.total = values.companiesFinancial.reduce(
                (currentTotal, companyFinancial) => {
                  return currentTotal + parseFloat(companyFinancial.value);
                },
                0
              );
            }
            setError("Não foi possível carregar os dados financeiros");
          })
          .catch((error) => {
            setError(error.message);
          });
      }
    }, [values.period]);
    return null;
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const ModalSubmit = () => {
    const { values } = useFormikContext();

    const saveModal = () => {
      const currentCompaniesFinancial = values.companiesFinancial.map((o) =>
        o.companyId === financeToUpdate.companyId ? financeToUpdate : o
      );
      values.companiesFinancial = currentCompaniesFinancial;
      values.total = values.companiesFinancial.reduce(
        (currentTotal, companyFinancial) => {
          return currentTotal + parseFloat(companyFinancial.value);
        },
        0
      );
      handleClose();
    };

    return (
      <CreateButton
        fullWidth
        variant="contained"
        color="primary"
        onClick={saveModal}
      >
        Salvar
      </CreateButton>
    );
  };

  return (
    <React.Fragment>
      <Helmet title="Financeiro" />

      <OverflowXGrid container spacing={6} justify="center">
        <Grid item lg={12} xl={9}>
          <Typography variant="h2" gutterBottom display="inline">
            Financeiro
          </Typography>

          <Divider my={6} />
          <Formik
            initialValues={{
              period: "",
              companiesFinancial: [],
              total: 0,
            }}
            onSubmit={(
              { period, companiesFinancial },
              { setStatus, setErrors }
            ) => {
              setSubmitAble(false);
              const financesToSave = companiesFinancial.map(
                (companyFinancial) => {
                  return {
                    year: period.year,
                    month: period.month,
                    companyId: companyFinancial.companyId,
                    fantasyName: companyFinancial.fantasyName,
                    type: "generated",
                    description: companyFinancial.contractType,
                    referenceValue: companyFinancial.referenceValue,
                    referenceTax: companyFinancial.referenceTax,
                    value: companyFinancial.value,
                  };
                }
              );
              axios
                .post(LOGIN_URL + "/finances/create-many", financesToSave, {
                  headers: {
                    Authorization: `Bearer ${jwt}`,
                  },
                })
                .then((response) => {
                  setSubmitAble(true);
                  if (response.status === 200) {
                    setStatus({ success: true });
                    return;
                  }
                  setStatus({ success: false });
                  // reject(response.data);
                })
                .catch((error) => {
                  setStatus({ success: false });
                  setErrors({ submit: error.error || "Algo deu errado" });
                  setSubmitAble(true);
                  // reject(error.message);
                });
            }}
          >
            {(formik) => (
              <form noValidate onSubmit={formik.handleSubmit}>
                {formik.errors.submit && (
                  <Alert mt={2} mb={1} severity="warning">
                    {formik.errors.submit}
                  </Alert>
                )}
                {!formik.errors.submit && formik.status?.success && (
                  <Alert mt={2} mb={1} severity="success">
                    Faturamento mensal gravado criado com sucesso.
                  </Alert>
                )}
                <Grid container spacing={2}>
                  <Grid item xl={12} xs={12}>
                    <TextField
                      type="text"
                      name="period"
                      label="Competência"
                      fullWidth
                      my={2}
                      select
                      {...formik.getFieldProps("period")}
                    >
                      {periods.map((period) => (
                        <MenuItem value={period.value}>{period.text}</MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Box item xl={6} xs={12}>
                    <Box mt={3}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Loja</TableCell>
                            <TableCell>CNPJ</TableCell>
                            <TableCell>Tipo</TableCell>
                            <TableCell>Valor de referência</TableCell>
                            <TableCell>Taxa de referência</TableCell>
                            <TableCell>Total dos serviços</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {formik.values.companiesFinancial.map(
                            (companyFinancial, index) => (
                              <TableRow key={companyFinancial.companyId}>
                                <TableCell>
                                  {index + 1} - {companyFinancial.fantasyName}
                                </TableCell>
                                <TableCell>
                                  {formatCNPJ(companyFinancial.cnpj)}
                                </TableCell>
                                <TableCell>
                                  {formatDescription(
                                    companyFinancial.contractType
                                  )}
                                </TableCell>
                                <TableCell align={"right"}>
                                  {intToCurrency(
                                    companyFinancial.referenceValue
                                  )}
                                </TableCell>
                                <TableCell align={"right"}>
                                  {formatTax(
                                    companyFinancial.contractType,
                                    companyFinancial.referenceTax
                                  )}
                                </TableCell>
                                <TableCell align={"right"}>
                                  {intToCurrency(companyFinancial.value)}
                                </TableCell>
                                <TableCell>
                                  <IconButton
                                    mx={2}
                                    aria-label="Edit"
                                    onClick={() => {
                                      setFinanceToUpdate(companyFinancial);
                                      handleOpen();
                                    }}
                                  >
                                    <EditIcon fontSize="small" />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            )
                          )}
                          <TableRow>
                            <TableCell align={"right"} colSpan={6}>
                              Total: {intToCurrency(formik.values.total)}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Box>
                  </Box>
                </Grid>
                <FormObserver />
                <CreateButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={!isSubmitAble}
                >
                  Gravar
                </CreateButton>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <ModalBox>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      {financeToUpdate.fantasyName}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      Alterar o valor gerado.
                    </Typography>
                    <Grid item xl={12} xs={12}>
                      <TextField
                        type="number"
                        name="referenceValue"
                        label="Valor de referência"
                        fullWidth
                        my={2}
                        value={financeToUpdate.referenceValue}
                        readonly
                      />
                    </Grid>
                    <Grid item xl={12} xs={12}>
                      <TextField
                        type="number"
                        name="referenceTax"
                        label="Taxa de referência"
                        fullWidth
                        my={2}
                        onChange={(event) => {
                          const newReferenceTax = event.target.value;
                          setFinanceToUpdate({
                            ...financeToUpdate,
                            referenceTax: newReferenceTax,
                            value:
                              financeToUpdate.contractType === "sale"
                                ? newReferenceTax
                                : (
                                    (newReferenceTax / 100) *
                                    financeToUpdate.referenceValue
                                  ).toFixed(2),
                          });
                        }}
                        value={financeToUpdate.referenceTax}
                      />
                    </Grid>
                    <Grid item xl={12} xs={12}>
                      <TextField
                        type="number"
                        name="value"
                        label="Valor"
                        fullWidth
                        my={2}
                        value={financeToUpdate.value}
                        readonly
                        onChange={(event) => {
                          const newValue = event.target.value;
                          setFinanceToUpdate({
                            ...financeToUpdate,
                            referenceTax: 0,
                            value: newValue,
                          });
                        }}
                      />
                    </Grid>
                    <ModalSubmit />
                  </ModalBox>
                </Modal>
              </form>
            )}
          </Formik>
        </Grid>
      </OverflowXGrid>
    </React.Fragment>
  );
}

export default withTheme(withRouter(FinancialForm));
