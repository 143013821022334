import axios from "../../../utils/axios";
import { LOGIN_URL } from "../../../constants/urls";
import _ from "lodash";

const loadCompanyFinances = async (jwt, id) => {
  const response = await axios.get(`${LOGIN_URL}/companies/${id}/finances`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });

  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error();
  }
};

export default async function userInDebt({ jwt, selectedCompany, access }) {
  if (access === 1) {
    return false;
  }
  const finances = await loadCompanyFinances(
    jwt,
    selectedCompany?.id ?? selectedCompany?._id
  );
  return !_.isEmpty(
    _.chain(finances)
      .filter((finance) => finance?.payment?.status === "OVERDUE")
      .uniq()
      .value()
  );
}
