import * as types from "../../constants";

export default function reducer(state = {}, actions) {
  switch (actions.type) {
    case types.AUTH_SIGN_IN_SUCCESS:
      return {
        ...state,
        user: {
          jwt: actions.jwt,
          name: actions.name,
          email: actions.email,
          companies: actions.companies,
          access: actions.access,
          selectedCompany: actions.selectedCompany,
          permissions: actions.permissions,
          requireToken: actions.requireToken,
          hasSecret: actions.hasSecret,
        },
      };

    case types.AUTH_SIGN_OUT:
      return {
        ...state,
        user: undefined,
      };

    case types.AUTH_SET_COMPANY:
      return {
        ...state,
        user: {
          ...state.user,
          selectedCompany: actions.company,
        },
      };

    default:
      return state;
  }
}
