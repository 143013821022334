import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

function Presentation() {
  const auth = useSelector((state) => state.authReducer);

  if (!auth.user) {
    return <Redirect to="/login" />;
  }

  return <Redirect to="/private" />;
}

export default Presentation;
