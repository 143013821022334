import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { Helmet } from "react-helmet-async";

import { Grid, Typography as MuiTypography } from "@material-ui/core";

import { spacing } from "@material-ui/system";

import Content from "./Content";
import Actions from "../common/Actions";

import loadData from "./loadData";
import { Alert } from "@material-ui/lab";
import userInDebt from "../../../common/hooks/inDebt";

const Typography = styled(MuiTypography)(spacing);

function Default() {
  const { user } = useSelector((state) => state.authReducer);
  const { loadPeriod } = useSelector((state) => state.periodReducer);
  const [dashboardData, setDashboardData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [isInDebt, setIsInDebt] = useState(false);

  const loadAndSave = async () => {
    setLoading(true);
    setIsInDebt(false);
    const inDebt = await userInDebt(user);
    if (inDebt) {
      setIsInDebt(true);
      setLoading(false);
      return;
    }
    const data = await loadData(user, loadPeriod());

    if (!data) {
      setError("Não foi possível carregar os dados");
    } else {
      setDashboardData(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadAndSave();
  }, [loadPeriod, user]);

  const companyMessage = user.selectedCompany
    ? `Aqui estão os dados da loja ${user.selectedCompany.fantasyName}`
    : "Nenhuma empresa está vinculada a esse usuário";

  return (
    <React.Fragment>
      <Helmet title="KPIs de Marketing" />
      <Grid justify="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            KPIs de Marketing
          </Typography>
          <Typography variant="subtitle1">
            Olá, {user.name}! {companyMessage}.{" "}
            <span role="img" aria-label="Waving Hand Sign">
              👋
            </span>
          </Typography>
        </Grid>

        {!!user.selectedCompany && (
          <Grid item>
            <Actions reload={loadAndSave} />
          </Grid>
        )}
      </Grid>

      {loading && (
        <Alert mt={2} mb={1} severity="info">
          Carregando...
        </Alert>
      )}

      {!!error && (
        <Alert mt={2} mb={1} severity="warning">
          {error}
        </Alert>
      )}

      {!!isInDebt && (
        <Alert mt={3} mb={2} severity="warning">
          Bloqueio financeiro automático pelo operador financeiro. Para liberar
          o app basta regularizar a situação financeira.
        </Alert>
      )}

      {!!user.selectedCompany && !error && !loading && !isInDebt && (
        <Content data={dashboardData} />
      )}
    </React.Fragment>
  );
}

export default Default;
