import { combineReducers } from "redux";

import themeReducer from "./themeReducer";
import authReducer from "./authReducer";
import periodReducer from "./periodReducer";

export const rootReducer = combineReducers({
  themeReducer,
  authReducer,
  periodReducer,
});
