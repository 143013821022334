import axios from "axios";
// import { setupCache } from "axios-cache-adapter";

// const cache = setupCache({
//   maxAge: 15 * 60 * 1000,
//   exclude: {
//     query: false,
//     paths: [
//       ".*\\/companies",
//       ".*\\/users",
//       ".*\\/validate-token",
//       ".*\\/companies\\/load",
//       ".*\\/logout",
//     ],
//   },
// });

export default axios.create({
  // adapter: cache.adapter,
  validateStatus: (status) => status < 500,
});
