import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";

import { Helmet } from "react-helmet-async";

import {
  Box,
  Divider as MuiDivider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography as MuiTypography,
  IconButton,
  TextField,
  MenuItem,
} from "@material-ui/core";

import { Delete as DeleteIcon, Edit as EditIcon } from "@material-ui/icons";

import { spacing } from "@material-ui/system";
import axios from "../../../utils/axios";
import { LOGIN_URL } from "../../../constants/urls";
import { Alert } from "@material-ui/lab";
import { Formik } from "formik";

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

const OverflowXGrid = styled(Grid)`
  overflow-x: scroll;
`;

function Users() {
  const history = useHistory();
  const [users, setUsers] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [loading, isLoading] = useState(true);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const {
    user: { jwt },
  } = useSelector((state) => state.authReducer);

  const loadList = () => {
    isLoading(true);
    setUsers([]);
    setCompanies([]);
    setSuccess(false);
    setError(false);
    axios
      .get(LOGIN_URL + "/users", {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
      .then((response) => {
        isLoading(false);
        if (response.status === 200) {
          return setUsers(response.data);
        }
        if (response.data.error) {
          setError(response.data.error);
        } else {
          setError("Algo deu errado");
        }
      })
      .catch((error) => {
        setError(error.message);
      });

    axios
      .get(LOGIN_URL + "/companies", {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
      .then((response) => {
        isLoading(false);
        if (response.status === 200) {
          return setCompanies(
            response.data.map((company) => company.fantasyName).sort()
          );
        }
        setError("Não foi possível carregar as empresas");
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  useEffect(loadList, []);

  const editUser = (user) => {
    history.push({
      pathname: "/admin/users/edit",
      state: {
        user,
      },
    });
  };

  const deleteUser = (email) => {
    setSuccess(false);
    setError(false);
    axios
      .delete(LOGIN_URL + "/users/delete", {
        data: {
          email,
        },
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          loadList();
          return setSuccess("Usuário deletado com sucesso");
        }
        setError("Algo deu errado");
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const isRowFiltered = (user, filters) => {
    if (
      filters.name &&
      !user.name
        .toLowerCase()
        .replace(" ", "")
        .includes(filters.name.toLowerCase().replace(" ", ""))
    ) {
      return false;
    }
    if (
      filters.company &&
      user.companies.filter(
        (company) => company.fantasyName === filters.company
      ).length === 0
    ) {
      return false;
    }
    return true;
  };

  return (
    <React.Fragment>
      <Helmet title="Usuários" />

      <OverflowXGrid container spacing={6}>
        <Grid item lg={12}>
          <Typography variant="h2" gutterBottom display="inline">
            Usuários
          </Typography>

          <Divider my={6} />

          {!!error && (
            <Alert mt={2} mb={1} severity="warning">
              {error}
            </Alert>
          )}

          {!!success && (
            <Alert mt={2} mb={1} severity="success">
              {success}
            </Alert>
          )}

          {loading && (
            <Typography gutterBottom display="inline">
              Carregando lista...
            </Typography>
          )}

          {!loading && (
            <Formik
              initialValues={{
                users,
              }}
            >
              {(formik) => (
                <form>
                  <TextField
                    type="text"
                    name="name"
                    label="Nome"
                    fullWidth
                    my={2}
                    {...formik.getFieldProps("name")}
                  />
                  <TextField
                    type="text"
                    name="company"
                    label="Loja"
                    fullWidth
                    my={2}
                    {...formik.getFieldProps("company")}
                    select
                  >
                    <MenuItem>Remove filtro</MenuItem>
                    {companies.map((company) => {
                      return <MenuItem value={company}>{company}</MenuItem>;
                    })}
                  </TextField>
                  <Divider my={6} />
                  <Box mt={3}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Nome</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell align="right">Ações</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {users.map((user) => {
                          if (isRowFiltered(user, formik.values)) {
                            return (
                              <TableRow key={user.email}>
                                <TableCell component="th" scope="user">
                                  {user.name}
                                </TableCell>
                                <TableCell>{user.email}</TableCell>
                                <TableCell align="right">
                                  <IconButton
                                    mx={2}
                                    aria-label="Delete"
                                    onClick={() => editUser(user)}
                                  >
                                    <EditIcon fontSize="small" />
                                  </IconButton>
                                  <IconButton
                                    mx={2}
                                    aria-label="Delete"
                                    onClick={() => deleteUser(user.email)}
                                  >
                                    <DeleteIcon fontSize="small" />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            );
                          }
                        })}
                      </TableBody>
                    </Table>
                  </Box>
                </form>
              )}
            </Formik>
          )}
        </Grid>
      </OverflowXGrid>
    </React.Fragment>
  );
}

export default Users;
