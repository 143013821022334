import * as types from "../../constants";
import { periods } from "../../pages/user/dashboards/common/Actions";

export const initialState = {
  loadPeriod: periods.month,
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.PERIOD_SET:
      return {
        ...state,
        loadPeriod: actions.loadPeriod,
      };

    default:
      return state;
  }
}
