import React from "react";
import { useSelector } from "react-redux";
import styled, { withTheme } from "styled-components/macro";

import { orange, green, red, grey } from "@material-ui/core/colors";

import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow as MuiTableRow,
  Typography,
  Chip as MuiChip,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import { Doughnut } from "react-chartjs-2";
import { fontSizes } from "../../../../constants/designSystem";

const Card = styled(MuiCard)(spacing);

const ChartTableWrapper = styled.div`
  height: 378px;
  width: 100%;
`;

const ChartWrapper = styled.div`
  height: 168px;
  position: relative;
`;

const DoughnutInner = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -22px;
  text-align: center;
  z-index: 0;
`;

const TableRow = styled(MuiTableRow)`
  height: 42px;
`;

const TableCell = styled(MuiTableCell)`
  padding-top: 0;
  padding-bottom: 0;
`;

const GreenText = styled.span`
  color: ${() => green[400]};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const RedText = styled.span`
  color: ${() => red[400]};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const GreyText = styled.span`
  color: ${() => grey[400]};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const Chip = styled(MuiChip)`
  height: 20px;
  font-size: 85%;
  background-color: ${(props) => props.theme.palette.secondary.main};
  color: ${(props) => props.theme.palette.common.white};

  span {
    padding-left: ${(props) => props.theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;
  }
`;

const currency = Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

const percent = Intl.NumberFormat("pt-BR", {
  style: "percent",
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

const comparePeriods = (lastPeriod, thisPeriod) => {
  let numericValue = (thisPeriod - lastPeriod) / lastPeriod;

  if (typeof numericValue !== "number" || !isFinite(numericValue)) {
    return {
      formatted: "-%",
      ColorText: GreyText,
    };
  }

  const plus = numericValue > 0 ? "+" : "";
  return {
    formatted: plus + percent.format(numericValue),
    ColorText: numericValue > 0 ? GreenText : RedText,
  };
};

const InvestmentsByChannel = ({
  theme,
  investmentData: { facebookAds, googleAds },
  lastInvestmentData: {
    facebookAds: lastFacebookAds,
    googleAds: lastGoogleAds,
  },
}) => {
  const { loadPeriod } = useSelector((state) => state.periodReducer);

  const { tag, legend } = loadPeriod();

  const data = {
    labels: ["Google Ads", "Meta Ads"],
    datasets: [
      {
        data: [googleAds, facebookAds],
        lastData: [lastGoogleAds, lastFacebookAds],
        backgroundColor: [
          theme.palette.secondary.main,
          red[500],
          orange[500],
          theme.palette.grey[200],
        ],
        borderWidth: 5,
        borderColor: theme.palette.background.paper,
      },
    ],
  };

  const totalNow = data.datasets[0].data.reduce((sum, value) => sum + value);
  const lastTotal = data.datasets[0].lastData.reduce(
    (sum, value) => sum + value
  );

  const compareAll = comparePeriods(lastTotal, totalNow);

  const title = (tooltip) => {
    return data.labels[tooltip[0].index];
  };

  const label = (tooltip) => {
    const value = data.datasets[0].data[tooltip.index];
    return currency.format(value);
  };

  const footer = (tooltip) => {
    const value = data.datasets[0].data[tooltip[0].index];
    const sum = data.datasets[0].data.reduce((acc, currency) => acc + currency);
    return percent.format(value / sum);
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      callbacks: {
        title,
        label,
        footer,
      },
      titleFontSize: fontSizes.normal,
      bodyFontSize: fontSizes.medium,
      footerFontSize: fontSizes.medium,
    },
    cutoutPercentage: 80,
  };

  return (
    <Card mb={3}>
      <CardHeader
        action={<Chip label={tag} />}
        title="Investimento por Canais"
      />

      <CardContent>
        <ChartTableWrapper>
          <ChartWrapper>
            <DoughnutInner variant="h4">
              <Typography variant="h4">{compareAll.formatted}</Typography>
              <Typography variant="caption">{legend}</Typography>
            </DoughnutInner>
            <Doughnut data={data} options={options} />
          </ChartWrapper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Origem</TableCell>
                <TableCell align="right">Investimento</TableCell>
                <TableCell align="right">Variação</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.labels.map((label, index) => {
                const { ColorText, formatted } = comparePeriods(
                  data.datasets[0].lastData[index],
                  data.datasets[0].data[index]
                );
                return (
                  <TableRow key={label}>
                    <TableCell component="th" scope="row">
                      {label}
                    </TableCell>
                    <TableCell align="right">
                      {currency.format(data.datasets[0].data[index])}
                    </TableCell>
                    <TableCell align="right">
                      <ColorText>{formatted}</ColorText>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </ChartTableWrapper>
      </CardContent>
    </Card>
  );
};

export default withTheme(InvestmentsByChannel);
