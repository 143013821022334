import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { withRouter } from "react-router-dom";

import { Helmet } from "react-helmet-async";
import styled, { withTheme } from "styled-components/macro";
import {
  Button,
  Grid,
  TextField as MuiTextField,
  Typography as MuiTypography,
  Divider as MuiDivider,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Alert } from "@material-ui/lab";
import axios from "../../utils/axios";
import { LOGIN_URL } from "../../constants/urls";
import CopyToClipboardButton from "../components/CopyToClipboardButton";

const TextField = styled(MuiTextField)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

const CreateButton = styled(Button)`
  padding: 10px 16px;
`;

function UserForm() {
  const { user } = useSelector((state) => state.authReducer);
  const { name, email, jwt, hasSecret } = user;
  const [secret, setSecret] = useState(false);
  const [secretConfigured, setSecretConfigured] = useState(false);

  const editUser = async (user, jwt) => {
    return new Promise((resolve, reject) => {
      axios
        .post(LOGIN_URL + "/my-account/edit", user, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
          validateStatus: (status) => {
            return true;
          },
        })
        .then((response) => {
          if (response.status === 200) {
            return resolve(response.data);
          }
          reject({ message: response.data.error });
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  };

  const twoFactor = async (params, jwt) => {
    return new Promise((resolve, reject) => {
      axios
        .post(LOGIN_URL + "/my-account/two-factor", params, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
          validateStatus: (status) => {
            return true;
          },
        })
        .then((response) => {
          if (response.status === 200) {
            if (response.data.qr) {
              setSecret({
                qr: response.data.qr,
                base32: response.data.secret.base32,
              });
            }
            return resolve(response.data);
          }
          reject({ message: response.data.error });
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  };

  const twoFactorEnable = async (userToken, jwt) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          LOGIN_URL + "/my-account/two-factor-enable",
          { base32secret: secret.base32, userToken },
          {
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
            validateStatus: (status) => {
              return true;
            },
          }
        )
        .then((response) => {
          if (response.status === 200 && response.data.verified) {
            setSecret(false);
            setSecretConfigured(true);
            return resolve(response.data);
          }
          reject({ message: response.data.message });
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  };

  return (
    <React.Fragment>
      <Helmet title="Minha Conta" />

      <Grid container spacing={6} justify="center">
        <Grid item sm={9} xs={12}>
          <Typography variant="h2" gutterBottom display="inline">
            Minha Conta
          </Typography>

          <Divider my={6} />
          <Typography variant="h3" gutterBottom display="inline">
            Editar Dados
          </Typography>
          <Formik
            initialValues={{
              submit: false,
              name,
              email,
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required("O nome é obrigatório"),
              email: Yup.string()
                .email("O email deve ser válido")
                .max(255)
                .required("O email é obrigatório"),
              password: Yup.string().max(255).required("A senha é obrigatória"),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                await editUser(values, jwt);
                setStatus({ success: true });
              } catch (error) {
                const message = error.message || "Algo deu errado";

                setStatus({ success: false });
                setErrors({ submit: message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              status,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                {errors.submit && (
                  <Alert mt={2} mb={1} severity="warning">
                    {errors.submit}
                  </Alert>
                )}
                {!errors.submit && status?.success && (
                  <Alert mt={2} mb={1} severity="success">
                    Conta alterada com sucesso!
                  </Alert>
                )}
                <Grid container spacing={2}>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      type="text"
                      name="name"
                      label="Nome (obrigatório)"
                      value={values.name}
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      type="email"
                      name="email"
                      label="Email (obrigatório)"
                      value={values.email}
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                    />
                  </Grid>
                  <Grid item xl={12} xs={12}>
                    <TextField
                      type="password"
                      name="password"
                      label="Senha (obrigatório)"
                      value={values.password}
                      error={Boolean(touched.password && errors.password)}
                      fullWidth
                      helperText={touched.password && errors.password}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                    />
                  </Grid>
                  <CreateButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Salvar
                  </CreateButton>
                </Grid>
                <Divider my={6} />
              </form>
            )}
          </Formik>

          <Typography variant="h3" gutterBottom display="inline">
            Editar Senha
          </Typography>

          <Formik
            initialValues={{
              submit: false,
              oldPassword: "",
              password: "",
              validatePassword: "",
            }}
            validationSchema={Yup.object().shape({
              password: Yup.string()
                .min(6, "No mínimo 6 caracteres")
                .max(255, "No máximo 255 caracteres")
                .required("A senha atual é obrigatória"),
              newPassword: Yup.string()
                .min(6, "No mínimo 6 caracteres")
                .max(255, "No máximo 255 caracteres")
                .required("A nova senha é obrigatória"),
              validateNewPassword: Yup.string().oneOf(
                [Yup.ref("newPassword"), null],
                "As novas senhas devem ser iguais"
              ),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                await editUser(values, jwt);
                setStatus({ success: true });
              } catch (error) {
                const message = error.message || "Algo deu errado";

                setStatus({ success: false });
                setErrors({ submit: message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              status,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                {errors.submit && (
                  <Alert mt={2} mb={1} severity="warning">
                    {errors.submit}
                  </Alert>
                )}
                {!errors.submit && status?.success && (
                  <Alert mt={2} mb={1} severity="success">
                    Senha alterada com sucesso!
                  </Alert>
                )}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      type="password"
                      name="password"
                      label="Senha Atual"
                      value={values.password}
                      error={Boolean(touched.password && errors.password)}
                      fullWidth
                      helperText={touched.password && errors.password}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={3}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      type="password"
                      name="newPassword"
                      label="Nova Senha"
                      value={values.newPassword}
                      error={Boolean(touched.newPassword && errors.newPassword)}
                      fullWidth
                      helperText={touched.newPassword && errors.newPassword}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={3}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      type="password"
                      name="validateNewPassword"
                      label="Repita a Nova Senha"
                      value={values.validateNewPassword}
                      error={Boolean(
                        touched.validateNewPassword &&
                          errors.validateNewPassword
                      )}
                      fullWidth
                      helperText={
                        touched.validateNewPassword &&
                        errors.validateNewPassword
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={3}
                    />
                  </Grid>
                  <CreateButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Salvar
                  </CreateButton>
                </Grid>
                <Divider my={6} />
              </form>
            )}
          </Formik>
          {secret ? (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h3" gutterBottom display="inline">
                    Autenticação 2 fatores
                  </Typography>
                </Grid>
                <Grid item xs={12} marginTop="5mm">
                  <Typography variant="h6" paragraph={true} display="inline">
                    Autenticação 2 fatores adiciona uma camada adicional de
                    segurança para sua conta requerindo mais do que apenas seu
                    e-mail e senha.
                    <br />
                    Para configurar, utilize algum aplicativo de armazenamento
                    de autenticadores (Google Authenticator, Authy, entre
                    outros), e leia o QR-Code abaixo:
                  </Typography>
                </Grid>
              </Grid>

              <Formik
                initialValues={{
                  submit: false,
                  token: "",
                }}
                validationSchema={Yup.object().shape({
                  token: Yup.string()
                    .min(6, "No mínimo 6 caracteres")
                    .max(6, "No máximo 6 caracteres")
                    .required("O token atual é obrigatória"),
                })}
                onSubmit={async (
                  values,
                  { setErrors, setStatus, setSubmitting }
                ) => {
                  try {
                    await twoFactorEnable(values.token, jwt);
                    setStatus({ success: true });
                  } catch (error) {
                    const message = error.message || "Algo deu errado";

                    setStatus({ success: false });
                    setErrors({ submit: message });
                    setSubmitting(false);
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                  status,
                }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    {errors.submit && (
                      <Alert mt={2} mb={1} severity="warning">
                        {errors.submit}
                      </Alert>
                    )}
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <img src={secret.qr} />
                      </Grid>
                      <Grid item xs={12}>
                        <CopyToClipboardButton value={secret.base32} />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          type="token"
                          name="token"
                          label="Insira o Token gerado"
                          value={values.token}
                          error={Boolean(touched.token && errors.token)}
                          fullWidth
                          helperText={touched.token && errors.token}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          my={3}
                        />
                      </Grid>
                      <CreateButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                      >
                        Salvar
                      </CreateButton>
                    </Grid>
                    <Divider my={6} />
                  </form>
                )}
              </Formik>
            </>
          ) : (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h3" gutterBottom display="inline">
                    Autenticação 2 fatores
                  </Typography>
                </Grid>
                <Grid item xs={12} marginTop="5mm">
                  <Typography variant="h6" paragraph={true} display="inline">
                    Autenticação 2 fatores adiciona uma camada adicional de
                    segurança para sua conta requerindo mais do que apenas seu
                    e-mail e senha.
                  </Typography>
                </Grid>
              </Grid>
              <Formik
                initialValues={{
                  submit: false,
                  password: "",
                  active: hasSecret,
                }}
                validationSchema={Yup.object().shape({
                  password: Yup.string()
                    .min(6, "No mínimo 6 caracteres")
                    .max(255, "No máximo 255 caracteres")
                    .required("A senha atual é obrigatória"),
                })}
                onSubmit={async (
                  values,
                  { setErrors, setStatus, setSubmitting }
                ) => {
                  try {
                    setSecretConfigured(false);
                    await twoFactor(
                      { twoFactor: values.active, password: values.password },
                      jwt
                    );
                    setStatus({ success: true, twoFactor: values.active });
                  } catch (error) {
                    const message = error.message || "Algo deu errado";

                    setStatus({ success: false });
                    setErrors({ submit: message });
                    setSubmitting(false);
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                  status,
                }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    {errors.submit && (
                      <Alert mt={2} mb={1} severity="warning">
                        {errors.submit}
                      </Alert>
                    )}
                    {((!errors.submit && status?.success && status.twoFactor) ||
                      secretConfigured) && (
                      <Alert mt={2} mb={1} severity="success">
                        Autenticação 2 fatores configurada com sucesso!
                      </Alert>
                    )}
                    {!errors.submit &&
                      status?.success &&
                      !status.twoFactor &&
                      !secretConfigured && (
                        <Alert mt={2} mb={1} severity="success">
                          Autenticação 2 fatores removida com sucesso!
                        </Alert>
                      )}
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          type="password"
                          name="password"
                          label="Senha Atual"
                          value={values.password}
                          error={Boolean(touched.password && errors.password)}
                          fullWidth
                          helperText={touched.password && errors.password}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          my={3}
                        />
                      </Grid>
                      <Grid item xl={12} xs={12}>
                        <FormControlLabel
                          control={
                            <Switch
                              name="active"
                              checked={values.active}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          }
                          label="Ativo"
                        />
                      </Grid>
                      <CreateButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                      >
                        Salvar
                      </CreateButton>
                    </Grid>
                    <Divider my={6} />
                  </form>
                )}
              </Formik>
            </>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default withTheme(withRouter(UserForm));
