import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography as MuiTypography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Typography = styled(MuiTypography)(spacing);

function Changelog() {
  return (
    <React.Fragment>
      <Helmet title="Politica de Privacidade" />

      <Grid container spacing={6} justify="center">
        <Grid item xs={12}>
          <Typography variant="h2" gutterBottom display="inline">
            Politica de Privacidade
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/">
              Minha Brand
            </Link>
            <Typography> Politica de Privacidade</Typography>
          </Breadcrumbs>

          <Divider my={6} />

          <Box mt={3}>
            <Typography variant="subtitle1">
              <p>
                Nós, do MINHA BRAND, nos preocupamos muito com seus dados e a
                sua privacidade, por isso vamos explicar como funciona nossa
                política de privacidade e o que fazemos com as informações que
                você nos fornece quando se cadastra, assim como com as
                informações que são compartilhadas por você ao usar nossa
                plataforma.
              </p>
              <p>
                Por favor, leia tudo com bastante atenção, pois ao utilizar
                nossos serviços você concorda com tudo o que falamos aqui. Esta
                Política de Privacidade faz parte de nossos Termos de Uso, e
                sempre que usar nossos serviços, você automaticamente declara
                que leu e aceitou tudo que explicamos aqui. .
              </p>
              <p>Tenha uma boa leitura!</p>
            </Typography>

            <Divider my={6} />

            <Typography variant="h3">
              1. INFORMAÇÕES QUE VOCÊ FORNECE
            </Typography>

            <Typography variant="subtitle1">
              <p>
                Quando você se cadastrar na plataforma da MINHA BRAND, você
                informará seu nome completo e seu endereço de e-mail, logo em
                seguida você deverá criar uma senha para efetuar o login na
                plataforma.
              </p>
              <p>
                Tais informações são muito importantes para que a MINHA BRAND te
                identifique.
              </p>
              <p>
                Para sua segurança, todas as informações do seu cadastro serão
                armazenadas e protegidas em plataforma de terceiro que fornece
                alta segurança de tratamento de dados.
              </p>
              <p>
                <b>ATENÇÃO:</b> A MINHA BRAND não tem acesso à sua senha, ela
                será armazenada na plataforma, com criptografia, para
                possibilitar o seu login, mas nós não conseguimos ver qual senha
                você cadastrou.
              </p>
            </Typography>

            <Divider my={6} />

            <Typography variant="h3">
              2. DADOS COLETADOS AO LONGO DO USO DOS SERVIÇOS
            </Typography>

            <Typography variant="subtitle1">
              <p>
                A MINHA BRAND utiliza a tecnologia do Google Analytics,
                fornecido pelo Google Inc., que coleta informações mediante o
                uso de cookies e de outras tecnologias. As informações
                coletadas, pelo uso do Google Analytics, são:
              </p>
              <ul>
                <li>endereço IP;</li>
                <li>
                  identificador único de cookie, informações de cookie,
                  informações do seu dispositivo e se o dispositivo acessa
                  determinados recursos;
                </li>
                <li>
                  identificador único de dispositivo e tipo de dispositivo;
                </li>
                <li>domínio, tipo de navegador e idioma;</li>
                <li>sistema operacional e configurações do sistema;</li>
                <li>país e fuso horário;</li>
                <li>preferências de idioma;</li>
                <li>sites visitados anteriormente;</li>
                <li>
                  informações sobre sua interação com nossa plataforma, tais
                  como comportamento de cliques, compras e preferências
                  indicadas;
                </li>
                <li>horários de acesso e URLs de referência.</li>
              </ul>
              <p>
                Os dados acima mencionados auxiliam a MINHA BRAND a identificar
                tendências e obter estatísticas para que possamos melhorar
                nossos serviços.
              </p>
              <p>
                Você pode impedir a coleta e armazenamento dos dados neste site:{" "}
                <a
                  href="https://tools.google.com/dlpage/gaoptout?hl=pt-BR"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://tools.google.com/dlpage/gaoptout?hl=pt-BR
                </a>
                .
              </p>
              <p>
                Para mais informações sobre as práticas de privacidade do
                Google, você pode visitar os seguintes sites:
              </p>
              <p>
                <a
                  href="https://support.google.com/analytics/answer/7318509?hl=pt-BR"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://support.google.com/analytics/answer/7318509?hl=pt-BR
                </a>
              </p>
              <p>
                <a
                  href="https://policies.google.com/privacy?hl=pt"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://policies.google.com/privacy?hl=pt
                </a>
              </p>
            </Typography>

            <Divider my={6} />

            <Typography variant="h3">
              3. USO QUE FAZEMOS COM AS INFORMAÇÕES COLETADAS
            </Typography>

            <Typography variant="h4">3.1. COMUNICAÇÃO</Typography>

            <Typography variant="subtitle1">
              <p>
                Pode ser que a gente precise falar com você sobre assuntos
                administrativos, como atualizações das funcionalidades da MINHA
                BRAND, por exemplo. Neste caso, os assuntos são realmente
                importantes e não há opção para cancelar o recebimento das
                mensagens.
              </p>
              <p>
                Caso a MINHA BRAND queira enviar à você outras informações,
                somente o faremos mediante sua prévia autorização.
              </p>
            </Typography>

            <Divider my={6} />

            <Typography variant="h3">
              4. COMO OS DADOS SÃO ARMAZENADOS E COMPARTILHADOS
            </Typography>

            <Typography variant="subtitle1">
              <p>
                Todos os dados inseridos por você ou coletados pela MINHA BRAND
                serão armazenados e protegidos em uma plataforma de terceiro que
                fornece alta segurança de tratamento de dados.
              </p>
              <p>
                A MINHA BRAND não compartilha quaisquer dados sobre você com
                terceiros, sejam parceiros ou outros Usuários, a não ser nas
                seguintes situações:{" "}
              </p>
              <ul>
                <li>Quando tivermos sua autorização;</li>
                <li>
                  Na hipótese de solicitação legal e situações de segurança.
                </li>
              </ul>
              <p>
                Para a hipótese de solicitação legal, toda vez que a MINHA BRAND
                receber uma decisão judicial ou um ofício de órgãos
                administrativos pedindo acesso aos seus dados, poderemos
                compartilhá-los com as pessoas ou órgãos responsáveis.
              </p>
            </Typography>

            <Divider my={6} />

            <Typography variant="h3">
              5. COMO EXCLUIR SUA CONTA E O QUE ACONTECE QUANDO ELA É DELETADA
            </Typography>

            <Typography variant="subtitle1">
              <p>
                Se você quiser excluir sua conta na MINHA BRAND, ficaremos muito
                tristes, mas você pode fazer isso a qualquer momento entrando em
                contato conosco através do e-mail de seu gerente de contas.
              </p>
              <p>
                Assim que você solicitar a exclusão da sua conta, nós
                retiraremos todas as informações prestadas por você e coletadas
                por nós, da base de dados da MINHA BRAND.
              </p>
              <p>
                A MINHA BRAND poderá armazenar os seus dados, após a exclusão da
                sua conta, em caso de cumprimento de obrigação legal, por
                exemplo, dentre outras hipóteses.
              </p>
            </Typography>

            <Divider my={6} />

            <Typography variant="h3">6. SEGURANÇA DAS INFORMAÇÕES</Typography>

            <Typography variant="subtitle1">
              <p>
                Ao cadastrar-se em nossa plataforma, você permite que a MINHA
                BRAND realize o tratamento de seus dados, como coleta,
                armazenamento, arquivamento e transferência, uma vez que tais
                tratamentos são essenciais para que você possa utilizar as
                funcionalidades oferecidas pela MINHA BRAND.
              </p>
              <p>
                Nós adotamos várias medidas de segurança para garantir que suas
                informações estejam protegidas contra acesso não autorizado e
                divulgação indevida.
              </p>
              <p>
                A plataforma se compromete a aplicar as medidas técnicas e
                organizativas aptas a protegerem os seus dados de acessos não
                autorizados e de situações de destruição, perda, alteração,
                comunicação ou difusão de tais dados.
              </p>
            </Typography>

            <Divider my={6} />

            <Typography variant="h3">
              7. SEUS DIREITOS SOBRE OS SEUS DADOS
            </Typography>

            <Typography variant="subtitle1">
              <p>
                Você tem o direito de questionar se a MINHA BRAND realiza o
                tratamento de algum dos seus dados, quais dados são armazenados
                e como esses dados são tratados.
              </p>

              <p>
                Caso algum dos seus dados estejam incorretos, incompletos ou
                desatualizados, você pode solicitar que a MINHA BRAND faça as
                alterações necessárias.
              </p>
              <p>
                Por fim, você sempre poderá solicitar a exclusão, bloqueio ou
                anonimização de seus dados, caso eles sejam desnecessários,
                excessivos ou não sejam tratados de acordo com a lei aplicável.
              </p>
              <p>
                A sua proteção é muito importante, assim, caso você faça alguma
                solicitação sobre os seus dados, pode ser que a MINHA BRAND
                solicite informações adicionais para confirmar a sua identidade.
              </p>
              <p>
                Se nós da MINHA BRAND constatarmos que a solicitação dos dados
                foi realizada por alguém que não se encontre em posição de
                fazê-lo, deixaremos de disponibilizar as informações requeridas,
                justificando o motivo da recusa.
              </p>
            </Typography>

            <Divider my={6} />

            <Typography variant="h3">
              8. ALTERAÇÕES NA POLÍTICA DE PRIVACIDADE
            </Typography>

            <Typography variant="subtitle1">
              <p>
                Assim como nossos Termos de Uso, podemos atualizar nossa
                Política de Privacidade para que sua experiência conosco seja
                mais completa, segura e especial. Sempre que fizermos isso,
                vamos te contar para que você saiba tudo que mudou! A
                comunicação será feita através do e-mail que você cadastrou, por
                isso é muito importante que você mantenha seu e-mail atualizado
                e permita o recebimento de mensagens da MINHA BRAND! Também
                recomendamos que de vez em quando você olhe novamente nossos
                Termos de Uso e Política de Privacidade para se informar sobre
                quaisquer atualizações.
              </p>
              <p>
                A atual versão da Política de Privacidade foi formulada e
                atualizada pela última vez em: <b>05 de agosto de 2021</b>
              </p>
              <p>
                Reservamos o direito de modificar essa Política de Privacidade a
                qualquer tempo, principalmente em função da adequação a
                eventuais alterações feitas em nosso site.
              </p>
            </Typography>

            <Divider my={6} />

            <Typography variant="h3">9. ISENÇÃO DE RESPONSABILIDADE</Typography>

            <Typography variant="subtitle1">
              <p>
                Conforme mencionado neste documento, embora adotemos padrões de
                segurança a fim de evitar incidentes, não há nenhuma página
                virtual inteiramente livre de riscos. Nesse sentido, a MINHA
                BRAND não se responsabiliza por:
              </p>
              <p>
                I – Quaisquer consequências decorrentes da negligência,
                imprudência ou imperícia dos Usuários em relação a seus dados
                individuais. Garantimos e nos responsabilizamos apenas pela
                segurança dos processos de tratamento de dados e do cumprimento
                das finalidades descritas no presente instrumento.
              </p>
              <p>
                Destacamos que a responsabilidade em relação à confidencialidade
                dos dados de acesso é do Usuário.
              </p>
              <p>
                II – Ações maliciosas de terceiros, como ataques de hackers,
                exceto se comprovada conduta culposa ou deliberada da MINHA
                BRAND.
              </p>
              <p>
                Destacamos que em caso de incidentes de segurança que possam
                gerar risco ou dano relevante para você ou qualquer um de nossos
                Usuários, comunicaremos aos afetados e à Autoridade Nacional de
                Proteção de Dados sobre o ocorrido e cumpriremos as providências
                necessárias.
              </p>
              <p>
                III – Inveracidade das informações inseridas pelo Usuário nos
                registros necessários para a utilização dos serviços da MINHA
                BRAND; quaisquer consequências decorrentes de informações falsas
                ou inseridas de má-fé são de inteiramente responsabilidade do
                Usuário.
              </p>
            </Typography>

            <Divider my={6} />

            <Typography variant="h3">10. FORO</Typography>

            <Typography variant="subtitle1">
              <p>
                Fica eleito o foro da Comarca de Ituporanga, Estado de Santa
                Catarina, como competente para dirimir quaisquer controvérsias
                decorrentes destes Termos de Uso, independentemente de qualquer
                outro, por mais privilegiado que seja ou venha a ser.
              </p>
            </Typography>

            <Divider my={6} />
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Changelog;
