import React from "react";
import styled, { withTheme } from "styled-components/macro";
import { Card as MuiCard, CardContent, CardHeader } from "@material-ui/core";

import { spacing } from "@material-ui/system";

import { fade } from "@material-ui/core/styles/colorManipulator";

import { Line } from "react-chartjs-2";

import { fontSizes } from "../../../../constants/designSystem";

const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
  height: 378px;
`;

const MONTHS = [
  "Jan",
  "Fev",
  "Mar",
  "Abr",
  "Mai",
  "Jun",
  "Jul",
  "Ago",
  "Set",
  "Out",
  "Nov",
  "Dez",
];

const percent = Intl.NumberFormat("pt-BR", {
  style: "percent",
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

function MediaCost({ theme, mediaData }) {
  const data = (canvas) => {
    const ctx = canvas.getContext("2d");

    const gradient = ctx.createLinearGradient(0, 0, 0, 300);
    gradient.addColorStop(0, fade(theme.palette.secondary.main, 0.0875));
    gradient.addColorStop(1, "rgba(0, 0, 0, 0)");

    const { labels, mediaCost } = mediaData
      .sort((a, b) =>
        a._id.year !== b._id.year
          ? a._id.year - b._id.year
          : a._id.month - b._id.month
      )
      .reduce(
        (acc, month) => {
          acc.labels.push(MONTHS[month._id.month - 1]);
          acc.mediaCost.push(month.mediaCost);

          return acc;
        },
        { labels: [], mediaCost: [] }
      );

    return {
      labels,
      datasets: [
        {
          label: "Custo de mídia",
          fill: false,
          backgroundColor: theme.palette.secondary.main,
          borderColor: theme.palette.secondary.main,
          data: mediaCost,
        },
      ],
    };
  };

  const label = (tooltip) => {
    return "Custo de mídia: " + percent.format(tooltip.yLabel / 100);
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      intersect: false,
      callbacks: {
        label,
      },
      titleFontSize: fontSizes.normal,
      bodyFontSize: fontSizes.medium,
      footerFontSize: fontSizes.medium,
    },
    hover: {
      intersect: true,
    },
    plugins: {
      filler: {
        propagate: false,
      },
    },
    scales: {
      xAxes: [
        {
          reverse: true,
          gridLines: {
            color: "rgba(0,0,0,0.0)",
          },
          ticks: {
            fontColor: theme.palette.text.secondary,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
            callback: (value) => percent.format(value / 100),
          },
          display: true,
          borderDash: [5, 5],
          gridLines: {
            color: "rgba(0,0,0,0.0375)",
            fontColor: "#fff",
          },
        },
      ],
    },
  };

  return (
    <Card mb={3}>
      <CardHeader title="Custo de mídia" />
      <CardContent>
        <ChartWrapper>
          <Line data={data} options={options} />
        </ChartWrapper>
      </CardContent>
    </Card>
  );
}
export default withTheme(MediaCost);
