import * as types from "../../constants";
import {
  signIn as authSignIn,
  signUp as authSignUp,
  resetPassword as authResetPassword,
  otpValidate,
} from "../../services/authService";

export function signIn(credentials) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_SIGN_IN_REQUEST });

    return authSignIn(credentials)
      .then((response) => {
        dispatch({
          type: types.AUTH_SIGN_IN_SUCCESS,
          jwt: response.jwt,
          name: response.name,
          email: response.email,
          companies: response.companies,
          access: response.access,
          selectedCompany: response.companies[0] || null,
          permissions: response.permissions,
          requireToken: response.requireToken,
          hasSecret: response.hasSecret,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_SIGN_IN_FAILURE });
        throw error;
      });
  };
}

export function tokenValidate(params) {
  return async (dispatch) => {
    dispatch({ type: types.TOKEN_VALIDATION_REQUEST });

    return otpValidate(params)
      .then((response) => {
        dispatch({
          type: types.AUTH_SIGN_IN_SUCCESS,
          jwt: response.jwt,
          name: response.name,
          email: response.email,
          companies: response.companies,
          access: response.access,
          selectedCompany: response.companies[0] || null,
          permissions: response.permissions,
          requireToken: response.requireToken,
          hasSecret: response.hasSecret,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_SIGN_IN_FAILURE });
        throw error;
      });
  };
}

export function signUp(credentials) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_SIGN_UP_REQUEST });

    return authSignUp(credentials)
      .then((response) => {
        dispatch({
          type: types.AUTH_SIGN_UP_SUCCESS,
          id: response.id,
          email: response.email,
          name: response.name,
          shops: response.shops,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_SIGN_UP_FAILURE });
        throw error;
      });
  };
}

export function signOut() {
  return async (dispatch) => {
    dispatch({
      type: types.AUTH_SIGN_OUT,
    });
  };
}

export function setShop(company) {
  return async (dispatch) => {
    dispatch({
      type: types.AUTH_SET_COMPANY,
      company,
    });
  };
}

export function updateUser(user) {
  return async (dispatch) => {
    dispatch({
      type: types.AUTH_SIGN_IN_SUCCESS,
      jwt: user.jwt,
      name: user.name,
      email: user.email,
      companies: user.companies,
      access: user.access,
      selectedCompany: user.companies[0] || null,
      permissions: user.permissions,
      hasSecret: user.hasSecret,
    });
  };
}

export function resetPassword(credentials) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_RESET_PASSWORD_REQUEST });

    return authResetPassword(credentials)
      .then((response) => {
        dispatch({
          type: types.AUTH_RESET_PASSWORD_SUCCESS,
          email: response.email,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_RESET_PASSWORD_FAILURE });
        throw error;
      });
  };
}
