import React from "react";
import { sub, format, startOfMonth, endOfMonth } from "date-fns";
import { ptBR } from "date-fns/locale";
import { differenceInCalendarDays } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import {
  Button as MuiButton,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Paper as MuiPaper,
} from "@material-ui/core";

import { Loop as LoopIcon } from "@material-ui/icons";

import { spacing } from "@material-ui/system";

import { setPeriod } from "../../../../redux/actions/periodActions";

const Button = styled(MuiButton)(spacing);
const Paper = styled(MuiPaper)(spacing);

const FilterButton = styled(Button)`
  margin-bottom: 10px;
`;

const SmallButton = styled(Button)`
  padding: 4px;
  min-width: 0;
  svg {
    width: 0.9em;
    height: 0.9em;
  }
`;

const FORMAT = "yyyy-MM-dd";
const DAY = "dd / MMMM";
const MONTH = "MMMM / yy";

export const periods = {
  today: () => ({
    timeInterval: "today",
    tag: "Hoje",
    label: `Hoje: ${format(new Date(), DAY, { locale: ptBR })}`,
    legend: "do que ontem",
    fromDate: format(new Date(), FORMAT),
    toDate: format(new Date(), FORMAT),
    predict: true,
    compareInterval: {
      fromDate: format(sub(new Date(), { days: 1 }), FORMAT),
      toDate: format(sub(new Date(), { days: 1 }), FORMAT),
    },
  }),
  yesterday: () => ({
    timeInterval: "yesterday",
    tag: "Ontem",
    label: `Ontem: ${format(sub(new Date(), { days: 1 }), DAY, {
      locale: ptBR,
    })}`,
    legend: "do que anteontem",
    fromDate: format(sub(new Date(), { days: 1 }), FORMAT),
    toDate: format(sub(new Date(), { days: 1 }), FORMAT),
    predict: true,
    compareInterval: {
      fromDate: format(sub(new Date(), { days: 2 }), FORMAT),
      toDate: format(sub(new Date(), { days: 2 }), FORMAT),
    },
  }),
  week: () => ({
    timeInterval: "default",
    tag: "7 dias",
    label: `Últimos 7 dias`,
    legend: "que 7 dias anteriores",
    fromDate: format(sub(new Date(), { days: 7 }), FORMAT),
    toDate: format(new Date(), FORMAT),
    predict: true,
    compareInterval: {
      fromDate: format(sub(new Date(), { days: 15 }), FORMAT),
      toDate: format(sub(new Date(), { days: 8 }), FORMAT),
    },
  }),
  thirty: () => ({
    timeInterval: "default",
    tag: "30 dias",
    label: `Últimos 30 dias`,
    legend: "que 30 dias anteriores",
    fromDate: format(sub(new Date(), { days: 30 }), FORMAT),
    toDate: format(new Date(), FORMAT),
    predict: true,
    compareInterval: {
      fromDate: format(sub(new Date(), { days: 61 }), FORMAT),
      toDate: format(sub(new Date(), { days: 31 }), FORMAT),
    },
  }),
  month: () => ({
    timeInterval: "default",
    tag: "Mês",
    label: `Mês: ${format(new Date(), MONTH, { locale: ptBR })}`,
    legend: "do que mês passado",
    fromDate: format(startOfMonth(new Date()), FORMAT),
    toDate: format(new Date(), FORMAT),
    predict: true,
    compareInterval: {
      fromDate: format(startOfMonth(sub(new Date(), { months: 1 })), FORMAT),
      toDate: format(sub(new Date(), { months: 1 }), FORMAT),
    },
  }),
  lastMonth: () => ({
    timeInterval: "default",
    tag: "Mês Pas",
    label: `Mês passado: ${format(sub(new Date(), { months: 1 }), MONTH, {
      locale: ptBR,
    })}`,
    legend: "do que mês retrasado",
    fromDate: format(startOfMonth(sub(new Date(), { months: 1 })), FORMAT),
    toDate: format(endOfMonth(sub(new Date(), { months: 1 })), FORMAT),
    predict: false,
    compareInterval: {
      fromDate: format(startOfMonth(sub(new Date(), { months: 2 })), FORMAT),
      toDate: format(endOfMonth(sub(new Date(), { months: 2 })), FORMAT),
    },
  }),
  last12Months: () => ({
    timeInterval: "default",
    tag: "12 meses",
    label: `Últimos 12 meses`,
    legend: "do que o período anterior",
    fromDate: format(
      startOfMonth(sub(new Date(), { years: 1, months: 1 })),
      FORMAT
    ),
    toDate: format(endOfMonth(sub(new Date(), { months: 1 })), FORMAT),
    predict: false,
    compareInterval: {
      fromDate: format(
        startOfMonth(sub(new Date(), { years: 2, months: 1 })),
        FORMAT
      ),
      toDate: format(
        endOfMonth(sub(new Date(), { years: 1, months: 1 })),
        FORMAT
      ),
    },
  }),
  custom: ({ customFrom, customTo }) => () => ({
    timeInterval: "default",
    tag: "Personalizado",
    label: customFrom
      ? `${format(
          new Date(`${customFrom} 00:00:00`),
          "dd/MM/yyyy"
        )} -> ${format(new Date(`${customTo} 00:00:00`), "dd/MM/yyyy")}`
      : "Personalizado",
    legend: "do que o período anterior",
    fromDate: customFrom,
    toDate: customTo,
    predict: false,
    compareInterval: {
      fromDate: format(
        sub(new Date(customFrom), {
          days:
            differenceInCalendarDays(new Date(customTo), new Date(customFrom)) +
            1,
        }),
        FORMAT
      ),
      toDate: format(sub(new Date(customFrom), { days: 1 }), FORMAT),
    },
  }),
};

function Actions({ reload }) {
  const dispatch = useDispatch();
  const { loadPeriod } = useSelector((state) => state.periodReducer);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dateDialogOpen, setDateDialogOpen] = React.useState(false);
  const [customFrom, setCustomFrom] = React.useState("");
  const [customTo, setCustomTo] = React.useState("");

  const { label } = loadPeriod();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (periodFuncion) => {
    dispatch(setPeriod(periodFuncion));
    handleClose();
  };

  const handleDateDialogOpening = () => {
    setDateDialogOpen(true);
  };

  const handleDateDialogClose = () => {
    setDateDialogOpen(false);
  };

  const handleDateDialogFilter = () => {
    handleDateDialogClose();
    handleSelect(periods.custom({ customFrom, customTo }));
  };

  return (
    <React.Fragment>
      <SmallButton size="small" mr={2} onClick={reload}>
        <LoopIcon />
      </SmallButton>
      <FilterButton
        variant="contained"
        color="secondary"
        aria-owns={anchorEl ? "simple-menu" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        {label}
      </FilterButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleSelect(periods.today)}>Hoje</MenuItem>
        <MenuItem onClick={() => handleSelect(periods.yesterday)}>
          Ontem
        </MenuItem>
        <MenuItem onClick={() => handleSelect(periods.week)}>
          Últimos 7 dias
        </MenuItem>
        <MenuItem onClick={() => handleSelect(periods.thirty)}>
          Últimos 30 dias
        </MenuItem>
        <MenuItem onClick={() => handleSelect(periods.month)}>
          Esse mês
        </MenuItem>
        <MenuItem onClick={() => handleSelect(periods.lastMonth)}>
          Mês passado
        </MenuItem>
        <MenuItem onClick={() => handleSelect(periods.last12Months)}>
          Últimos 12 meses
        </MenuItem>
        <MenuItem onClick={() => handleDateDialogOpening()}>
          Personalizado
        </MenuItem>
      </Menu>
      <Dialog open={dateDialogOpen} onClose={handleDateDialogClose}>
        <DialogTitle>Filtro personalizado</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Selecione um período para filtrar os dados.
          </DialogContentText>
          <Paper mt={3}>
            <TextField
              type="date"
              autoFocus
              id="fromDate"
              label="Data Inicial"
              fullWidth
              value={customFrom}
              onChange={(e) => setCustomFrom(e.target.value)}
              variant="standard"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Paper>
          <Paper mt={3}>
            <TextField
              type="date"
              id="toDate"
              label="Data Final"
              fullWidth
              value={customTo}
              onChange={(e) => setCustomTo(e.target.value)}
              variant="standard"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDateDialogClose}>Cancelar</Button>
          <Button onClick={handleDateDialogFilter}>Filtrar</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default Actions;
