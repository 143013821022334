import { Button, Snackbar } from "@material-ui/core";
import { useState } from "react";

const CopyToClipboardButton = ({ value }) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(value);
  };

  return (
    <>
      <Button onClick={handleClick} variant="contained">
        Clique aqui para copiar
      </Button>
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={2000}
        message="Copiado para área de transferência"
      />
    </>
  );
};

export default CopyToClipboardButton;
