import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { Helmet } from "react-helmet-async";

import { Grid, Typography as MuiTypography } from "@material-ui/core";

import { spacing } from "@material-ui/system";

import Actions from "../common/Actions";

import loadData from "./loadData";
import { Alert } from "@material-ui/lab";
import Content from "./Content";

const Typography = styled(MuiTypography)(spacing);

function GMVDashboard() {
  const { user } = useSelector((state) => state.authReducer);
  const { loadPeriod } = useSelector((state) => state.periodReducer);
  const [dashboardData, setDashboardData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  const loadAndSave = async () => {
    setLoading(true);
    const data = await loadData(user, loadPeriod());

    if (!data) {
      setError("Não foi possível carregar os dados");
    } else {
      setDashboardData(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadAndSave();
  }, [loadPeriod, user]);

  return (
    <React.Fragment>
      <Helmet title="GMV" />
      <Grid justify="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            GMV
          </Typography>
        </Grid>

        <Grid item>
          <Actions reload={loadAndSave} />
        </Grid>
      </Grid>

      {loading && (
        <Alert mt={2} mb={1} severity="info">
          Carregando...
        </Alert>
      )}

      {!!error && (
        <Alert mt={2} mb={1} severity="warning">
          {error}
        </Alert>
      )}

      {!error && !loading && <Content data={dashboardData} />}
    </React.Fragment>
  );
}

export default GMVDashboard;
