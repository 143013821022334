import React from "react";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { withRouter } from "react-router-dom";

import { Helmet } from "react-helmet-async";
import styled, { withTheme } from "styled-components/macro";
import {
  Button,
  Grid,
  TextField as MuiTextField,
  Typography as MuiTypography,
  Divider as MuiDivider,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Switch,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Alert } from "@material-ui/lab";
import axios from "../../../utils/axios";
import { LOGIN_URL } from "../../../constants/urls";

const TextField = styled(MuiTextField)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

const CreateButton = styled(Button)`
  padding: 10px 16px;
`;

const FacebookCopyGrid = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const createCompany = async (
  {
    cnpj,
    companyName,
    fantasyName,
    stateInscription,
    active,
    address,
    emails,
    phones,
    credentials,
    facebookTokenToAll,
    financial,
  },
  jwt
) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        LOGIN_URL + "/companies/create",
        {
          cnpj,
          companyName,
          fantasyName,
          stateInscription,
          active,
          address,
          emails,
          phones,
          credentials,
          facebookTokenToAll,
          financial,
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          return resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error.message);
      });
  });
};

const editCompany = async (company, jwt, id) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        LOGIN_URL + "/companies/edit",
        {
          company,
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          return resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error.message);
      });
  });
};

const ACTIONS = {
  create: {
    title: "Criar Nova Empresa",
    button: "Criar Empresa",
    action: createCompany,
    success: "Empresa criada com sucesso",
  },
  edit: {
    title: "Editar Empresa",
    button: "Editar Empresa",
    action: editCompany,
    success: "Empresa alterada com sucesso",
  },
};

function formatFinancialValueToCsv(financialValues) {
  if (financialValues == null) return;
  return financialValues
    .map((financialValue) => {
      return `${financialValue?.startAt};${financialValue?.value}`;
    })
    .join(`\n`);
}

function formatCsvToFinancialValue(csvData) {
  if (!csvData) {
    return;
  }
  return csvData.split(`\n`).map((line) => {
    const lineValues = line.split(";");
    if (lineValues.length == 2) {
      return {
        startAt: lineValues[0],
        value: lineValues[1],
      };
    }
  });
}

function CompanyForm({ location: { state } }) {
  const {
    user: { jwt },
  } = useSelector((state) => state.authReducer);

  let action = ACTIONS.create;
  let initialValues = { active: true };
  if (state && state.company) {
    action = ACTIONS.edit;
    initialValues = {
      _id: state.company._id,
      cnpj: state.company.cnpj,
      companyName: state.company.companyName,
      fantasyName: state.company.fantasyName,
      stateInscription: state.company.stateInscription,
      active: state.company.active,
      address: state.company.address?.address,
      addressNumber: state.company.address?.number,
      addressDistrict: state.company.address?.district,
      addressCity: state.company.address?.city,
      addressState: state.company.address?.state,
      addressCep: state.company.address?.cep,
      addressComplement: state.company.address?.complement,
      emails: state.company.emails,
      phones: state.company.phones,

      magaUrl: state.company.credentials.magazord.url,
      magaPassword: state.company.credentials.magazord.password,
      magaToken: state.company.credentials.magazord.token,
      magaStore: state.company.credentials.magazord.store,

      panoramaUrl: state.company.credentials.panorama.url,
      panoramaPassword: state.company.credentials.panorama.password,
      panoramaToken: state.company.credentials.panorama.token,
      panoramaStore: state.company.credentials.panorama.store,

      uoouToken: state.company.credentials?.uoou?.token,
      uoouChannelCode: state.company.credentials?.uoou?.channelCode,

      orderSheetsId: state.company.credentials?.orderSheets?.id,

      googleCustomerId: state.company.credentials.google.customerId,

      facebookAccountId: state.company.credentials.facebook.accountId,
      facebookToken: state.company.credentials.facebook.token,

      financialContractType: state.company?.financial?.contractType,
      financialMinimumValue: state.company?.financial?.minimumValue,
      financialContractValues: formatFinancialValueToCsv(
        state.company?.financial?.values
      ),
      financialDueDay: state.company?.financial?.dueDay,
    };
  }

  return (
    <React.Fragment>
      <Helmet title="Usuários" />

      <Grid container spacing={6} justify="center">
        <Grid item lg={12} xl={9}>
          <Typography variant="h2" gutterBottom display="inline">
            {action.title}
          </Typography>

          <Divider my={6} />
          <Formik
            initialValues={{
              submit: false,
              ...initialValues,
            }}
            validationSchema={Yup.object().shape({
              cnpj: Yup.string().required("O CNPJ é obrigatório"),
              companyName: Yup.string().required(
                "A Razão Social é obrigatória"
              ),
              fantasyName: Yup.string().required(
                "O Nome Fantasia é obrigatório"
              ),
              financialDueDay: Yup.number().min(1).max(28),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting, resetForm }
            ) => {
              try {
                const address = {
                  address: values.address,
                  number: values.addressNumber,
                  district: values.addressDistrict,
                  city: values.addressCity,
                  state: values.addressState,
                  cep: values.addressCep,
                  complement: values.addressComplement,
                };
                const credentials = {
                  magazord: {
                    url: values.magaUrl,
                    password: values.magaPassword,
                    token: values.magaToken,
                    store: values.magaStore,
                  },
                  panorama: {
                    url: values.panoramaUrl,
                    password: values.panoramaPassword,
                    token: values.panoramaToken,
                    store: values.panoramaStore,
                  },
                  uoou: {
                    token: values.uoouToken,
                    channelCode: values.uoouChannelCode,
                  },
                  orderSheets: {
                    id: values.orderSheetsId,
                  },
                  google: {
                    customerId: values.googleCustomerId,
                  },
                  facebook: {
                    accountId: values.facebookAccountId,
                    token: values.facebookToken,
                  },
                };
                const financial = {
                  contractType: values.financialContractType,
                  minimumValue: values.financialMinimumValue,
                  values: formatCsvToFinancialValue(
                    values.financialContractValues
                  ),
                  dueDay: values.financialDueDay,
                };
                await action.action(
                  { ...values, credentials, address, financial },
                  jwt,
                  initialValues._id
                );
                setStatus({ success: true });
              } catch (error) {
                const message = error.error || "Algo deu errado";

                setStatus({ success: false });
                setErrors({ submit: message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              status,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                {errors.submit && (
                  <Alert mt={2} mb={1} severity="warning">
                    {errors.submit}
                  </Alert>
                )}
                {!errors.submit && status?.success && (
                  <Alert mt={2} mb={1} severity="success">
                    {action.success}
                  </Alert>
                )}
                <Grid container spacing={2}>
                  <Grid item xl={6} xs={12}>
                    <TextField
                      type="text"
                      name="cnpj"
                      label="CNPJ"
                      value={values.cnpj}
                      error={Boolean(touched.cnpj && errors.cnpj)}
                      fullWidth
                      helperText={touched.cnpj && errors.cnpj}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                    />
                  </Grid>
                  <Grid item xl={6} xs={12}>
                    <TextField
                      type="text"
                      name="fantasyName"
                      label="Nome Fantasia"
                      value={values.fantasyName}
                      error={Boolean(touched.fantasyName && errors.fantasyName)}
                      fullWidth
                      helperText={touched.fantasyName && errors.fantasyName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                    />
                  </Grid>
                  <Grid item xl={12} xs={12}>
                    <TextField
                      type="text"
                      name="companyName"
                      label="Razão Social"
                      value={values.companyName}
                      error={Boolean(touched.companyName && errors.companyName)}
                      fullWidth
                      helperText={touched.companyName && errors.companyName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                    />
                  </Grid>
                  <Grid item xl={12} xs={12}>
                    <TextField
                      type="text"
                      name="stateInscription"
                      label="Inscrição Estadual"
                      value={values.stateInscription}
                      error={Boolean(
                        touched.stateInscription && errors.stateInscription
                      )}
                      fullWidth
                      helperText={
                        touched.stateInscription && errors.stateInscription
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                    />
                  </Grid>
                  <Grid item xl={12} xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          name="active"
                          checked={values.active}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      }
                      label="Ativo"
                    />
                  </Grid>

                  <Grid item xl={12} xs={12}>
                    <Divider my={6} />

                    <Typography variant="h3" gutterBottom display="inline">
                      Contato
                    </Typography>
                  </Grid>
                  <Grid item sm={8} xs={12}>
                    <TextField
                      type="text"
                      name="address"
                      label="Endereço"
                      value={values.address}
                      error={Boolean(touched.address && errors.address)}
                      fullWidth
                      helperText={touched.address && errors.address}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <TextField
                      type="text"
                      name="addressNumber"
                      label="Número"
                      value={values.addressNumber}
                      error={Boolean(
                        touched.addressNumber && errors.addressNumber
                      )}
                      fullWidth
                      helperText={touched.addressNumber && errors.addressNumber}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      type="text"
                      name="addressDistrict"
                      label="Bairro"
                      value={values.addressDistrict}
                      error={Boolean(
                        touched.addressDistrict && errors.addressDistrict
                      )}
                      fullWidth
                      helperText={
                        touched.addressDistrict && errors.addressDistrict
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      type="text"
                      name="addressCity"
                      label="Cidade"
                      value={values.addressCity}
                      error={Boolean(touched.addressCity && errors.addressCity)}
                      fullWidth
                      helperText={touched.addressCity && errors.addressCity}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      type="text"
                      name="addressState"
                      label="Estado"
                      value={values.addressState}
                      error={Boolean(
                        touched.addressState && errors.addressState
                      )}
                      fullWidth
                      helperText={touched.addressState && errors.addressState}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      type="text"
                      name="addressCep"
                      label="CEP"
                      value={values.addressCep}
                      error={Boolean(touched.addressCep && errors.addressCep)}
                      fullWidth
                      helperText={touched.addressCep && errors.addressCep}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                    />
                  </Grid>
                  <Grid item xl={12} xs={12}>
                    <TextField
                      type="text"
                      name="addressComplement"
                      label="Complemento"
                      value={values.addressComplement}
                      error={Boolean(
                        touched.addressComplement && errors.addressComplement
                      )}
                      fullWidth
                      helperText={
                        touched.addressComplement && errors.addressComplement
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                    />
                  </Grid>
                  <Grid item xl={12} xs={12}>
                    <TextField
                      type="text"
                      name="phones"
                      label="Telefones (separados por vírgula)"
                      value={values.phones}
                      error={Boolean(touched.phones && errors.phones)}
                      fullWidth
                      helperText={touched.phones && errors.phones}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                    />
                  </Grid>
                  <Grid item xl={12} xs={12}>
                    <TextField
                      type="text"
                      name="emails"
                      label="Emails (separados por vírgula)"
                      value={values.emails}
                      error={Boolean(touched.emails && errors.emails)}
                      fullWidth
                      helperText={touched.emails && errors.emails}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                    />
                  </Grid>

                  <Grid item xl={12} xs={12}>
                    <Divider my={6} />

                    <Typography variant="h3" gutterBottom display="inline">
                      Magazord
                    </Typography>
                  </Grid>
                  <Grid item xl={6} xs={12}>
                    <TextField
                      type="text"
                      name="magaUrl"
                      label="URL"
                      value={values.magaUrl}
                      error={Boolean(touched.magaUrl && errors.magaUrl)}
                      fullWidth
                      helperText={touched.magaUrl && errors.magaUrl}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                    />
                  </Grid>
                  <Grid item xl={6} xs={12}>
                    <TextField
                      type="text"
                      name="magaPassword"
                      label="Senha"
                      value={values.magaPassword}
                      error={Boolean(
                        touched.magaPassword && errors.magaPassword
                      )}
                      fullWidth
                      helperText={touched.magaPassword && errors.magaPassword}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                    />
                  </Grid>
                  <Grid item xl={6} xs={12}>
                    <TextField
                      type="text"
                      name="magaToken"
                      label="Token"
                      value={values.magaToken}
                      error={Boolean(touched.magaToken && errors.magaToken)}
                      fullWidth
                      helperText={touched.magaToken && errors.magaToken}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                    />
                  </Grid>
                  <Grid item xl={6} xs={12}>
                    <TextField
                      type="text"
                      name="magaStore"
                      label="Loja (opcional)"
                      value={values.magaStore}
                      error={Boolean(touched.magaStore && errors.magaStore)}
                      fullWidth
                      helperText={touched.magaStore && errors.magaStore}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                    />
                  </Grid>

                  <Grid item xl={12} xs={12}>
                    <Divider my={6} />

                    <Typography variant="h3" gutterBottom display="inline">
                      Panorama
                    </Typography>
                  </Grid>
                  <Grid item xl={6} xs={12}>
                    <TextField
                      type="text"
                      name="panoramaUrl"
                      label="URL"
                      value={values.panoramaUrl}
                      error={Boolean(touched.panoramaUrl && errors.panoramaUrl)}
                      fullWidth
                      helperText={touched.panoramaUrl && errors.panoramaUrl}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                    />
                  </Grid>
                  <Grid item xl={6} xs={12}>
                    <TextField
                      type="text"
                      name="panoramaPassword"
                      label="Usuário"
                      value={values.panoramaPassword}
                      error={Boolean(
                        touched.panoramaPassword && errors.panoramaPassword
                      )}
                      fullWidth
                      helperText={
                        touched.panoramaPassword && errors.panoramaPassword
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                    />
                  </Grid>
                  <Grid item xl={6} xs={12}>
                    <TextField
                      type="text"
                      name="panoramaToken"
                      label="Token"
                      value={values.panoramaToken}
                      error={Boolean(
                        touched.panoramaToken && errors.panoramaToken
                      )}
                      fullWidth
                      helperText={touched.panoramaToken && errors.panoramaToken}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                    />
                  </Grid>
                  <Grid item xl={6} xs={12}>
                    <TextField
                      type="text"
                      name="panoramaStore"
                      label="Loja (opcional)"
                      value={values.panoramaStore}
                      error={Boolean(
                        touched.panoramaStore && errors.panoramaStore
                      )}
                      fullWidth
                      helperText={touched.panoramaStore && errors.panoramaStore}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                    />
                  </Grid>

                  <Grid item xl={12} xs={12}>
                    <Divider my={6} />

                    <Typography variant="h3" gutterBottom display="inline">
                      Uoou
                    </Typography>
                  </Grid>
                  <Grid item xl={6} xs={12}>
                    <TextField
                      type="text"
                      name="uoouToken"
                      label="Token"
                      value={values.uoouToken}
                      error={Boolean(touched.uoouToken && errors.uoouToken)}
                      fullWidth
                      helperText={touched.uoouToken && errors.uoouToken}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                    />
                  </Grid>
                  <Grid item xl={6} xs={12}>
                    <TextField
                      type="text"
                      name="uoouChannelCode"
                      label="Channel code"
                      value={values.uoouChannelCode}
                      error={Boolean(
                        touched.uoouChannelCode && errors.uoouChannelCode
                      )}
                      fullWidth
                      helperText={
                        touched.uoouChannelCode && errors.uoouChannelCode
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                    />
                  </Grid>
                  <Grid item xl={12} xs={12}>
                    <Divider my={6} />

                    <Typography variant="h3" gutterBottom display="inline">
                      Planilha
                    </Typography>
                  </Grid>
                  <Grid item xl={12} xs={12}>
                    <TextField
                      type="text"
                      name="orderSheetsId"
                      label="ID"
                      value={values.orderSheetsId}
                      error={Boolean(
                        touched.orderSheetsId && errors.orderSheetsId
                      )}
                      fullWidth
                      helperText={touched.orderSheetsId && errors.orderSheetsId}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                    />
                  </Grid>

                  <Grid item xl={12} xs={12}>
                    <Divider my={6} />

                    <Typography variant="h3" gutterBottom display="inline">
                      Google
                    </Typography>
                  </Grid>
                  <Grid item xl={12} xs={12}>
                    <TextField
                      type="text"
                      name="googleCustomerId"
                      label="ID"
                      value={values.googleCustomerId}
                      error={Boolean(
                        touched.googleCustomerId && errors.googleCustomerId
                      )}
                      fullWidth
                      helperText={
                        touched.googleCustomerId && errors.googleCustomerId
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                    />
                  </Grid>

                  <Grid item xl={12} xs={12}>
                    <Divider my={6} />

                    <Typography variant="h3" gutterBottom display="inline">
                      Meta
                    </Typography>
                  </Grid>
                  <Grid item xl={10} xs={12}>
                    <TextField
                      type="text"
                      name="facebookToken"
                      label="Token"
                      value={values.facebookToken}
                      error={Boolean(
                        touched.facebookToken && errors.facebookToken
                      )}
                      fullWidth
                      helperText={touched.facebookToken && errors.facebookToken}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                    />
                  </Grid>
                  <FacebookCopyGrid item xl={2} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="facebookTokenToAll"
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      }
                      label="Copiar para todos"
                    />
                  </FacebookCopyGrid>
                  <Grid item xl={12} xs={12}>
                    <TextField
                      type="text"
                      name="facebookAccountId"
                      label="ID"
                      value={values.facebookAccountId}
                      error={Boolean(
                        touched.facebookAccountId && errors.facebookAccountId
                      )}
                      fullWidth
                      helperText={
                        touched.facebookAccountId && errors.facebookAccountId
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                    />
                  </Grid>
                  <Grid item xl={12} xs={12}>
                    <Divider my={6} />

                    <Typography variant="h3" gutterBottom display="inline">
                      Financeiro
                    </Typography>
                  </Grid>
                  <Grid item xl={12} xs={12}>
                    <TextField
                      type="text"
                      name="financialContractType"
                      label="Tipo do contrato"
                      value={values.financialContractType}
                      error={Boolean(
                        touched.financialContractType &&
                          errors.financialContractType
                      )}
                      fullWidth
                      helperText={
                        touched.financialContractType &&
                        errors.financialContractType
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                      select
                    >
                      <MenuItem value="sale">Sobre o valor de vendas</MenuItem>
                      <MenuItem value="investment">
                        Sobre o valor de investimento
                      </MenuItem>
                      <MenuItem value="sale-percentage">
                        % (percentual) sobre o valor de vendas
                      </MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xl={12} xs={12}>
                    <TextField
                      type="number"
                      name="financialMinimumValue"
                      label="Valor mínimo (sem divisão de milhar, decimal por ponto .)"
                      value={values.financialMinimumValue}
                      error={Boolean(
                        touched.financialMinimumValue &&
                          errors.financialMinimumValue
                      )}
                      fullWidth
                      helperText={
                        touched.financialMinimumValue &&
                        errors.financialMinimumValue
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                    />
                  </Grid>
                  <Grid item xl={12} xs={12}>
                    <TextField
                      type="number"
                      name="financialDueDay"
                      label="Dia de vencimento (default: dia 15)"
                      value={values.financialDueDay}
                      error={Boolean(
                        touched.financialDueDay && errors.financialDueDay
                      )}
                      fullWidth
                      helperText={
                        touched.financialDueDay && errors.financialDueDay
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                      InputProps={{ inputProps: { min: 1, max: 28 } }}
                    />
                  </Grid>
                  <Grid item xl={12} xs={12}>
                    <TextField
                      type="text"
                      name="financialContractValues"
                      label="Valores do contrato"
                      value={values.financialContractValues}
                      error={Boolean(
                        touched.financialContractValues &&
                          errors.financialContractValues
                      )}
                      fullWidth
                      helperText={
                        touched.financialContractValues &&
                        errors.financialContractValues
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                      multiline
                      rows={20}
                    />
                  </Grid>
                  <Divider my={6} />
                </Grid>
                <CreateButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                >
                  {action.button}
                </CreateButton>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default withTheme(withRouter(CompanyForm));
