import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";

import { Helmet } from "react-helmet-async";
import styled, { withTheme } from "styled-components/macro";
import {
  Button,
  Grid,
  TextField as MuiTextField,
  Typography as MuiTypography,
  Divider as MuiDivider,
  MenuItem,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Alert } from "@material-ui/lab";
import axios from "../../../utils/axios";
import { LOGIN_URL } from "../../../constants/urls";

const TextField = styled(MuiTextField)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

const CreateButton = styled(Button)`
  padding: 10px 16px;
`;

function FinancialManualForm({ location: { state } }) {
  const {
    user: { jwt },
  } = useSelector((state) => state.authReducer);
  const [companies, setCompanies] = useState([]);
  const [loading, isLoading] = useState(true);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const currentDate = new Date();
  let periods = [];
  for (let i = 0; i < 12; i++) {
    let month;
    let year;
    if (currentDate.getMonth() + i > 12) {
      month = currentDate.getMonth() + i - 12;
      year = currentDate.getFullYear() + 1;
    } else if (currentDate.getMonth() + i === 0) {
      month = 12;
      year = currentDate.getFullYear() - 1;
    } else {
      month = currentDate.getMonth() + i;
      year = currentDate.getFullYear();
    }
    month = month.toString().padStart(2, "0");
    periods.push({
      value: `${month}/${year}`,
      text: `${month}/${year}`,
    });
  }

  const loadList = () => {
    isLoading(true);
    setCompanies([]);
    setSuccess(false);
    setError(false);
    axios
      .get(LOGIN_URL + "/companies?active=true", {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
      .then((response) => {
        isLoading(false);
        if (response.status === 200) {
          const companiesSorted = response.data.sort((company1, company2) =>
            company1.fantasyName.localeCompare(company2.fantasyName)
          );
          return setCompanies(companiesSorted);
        }
        setError("Não foi possível carregar as empresas");
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  useEffect(loadList, []);

  return (
    <React.Fragment>
      <Helmet title="Financeiro" />

      <Grid container spacing={6} justify="center">
        <Grid item lg={12} xl={9}>
          <Typography variant="h2" gutterBottom display="inline">
            Financeiro
          </Typography>

          <Divider my={6} />
          <Formik
            initialValues={{
              company: "",
              period: "",
              description: "",
              value: "",
            }}
            validationSchema={Yup.object().shape({
              company: Yup.string().required("A Empresa é obrigatório"),
              description: Yup.string().required("A Descrição é obrigatório"),
              value: Yup.string().required("O Valor é obrigatório"),
            })}
            onSubmit={(
              { company, description, serviceDescription, value, period },
              { setStatus, setErrors }
            ) => {
              let [month, year] = period.split("/");
              const financeToSave = {
                year,
                month,
                companyId: company,
                type: "manual",
                description,
                value,
                serviceDescription,
              };
              axios
                .post(LOGIN_URL + "/finances/create", financeToSave, {
                  headers: {
                    Authorization: `Bearer ${jwt}`,
                  },
                })
                .then((response) => {
                  if (response.status === 200) {
                    setStatus({ success: true });
                    setShowSuccess(true);
                    setTimeout(() => {
                      setShowSuccess(false);
                    }, 2000);
                    return;
                  }
                  setStatus({ success: false });
                  // reject(response.data);
                })
                .catch((error) => {
                  setStatus({ success: false });
                  setErrors({ submit: error.error || "Algo deu errado" });
                  // reject(error.message);
                });
            }}
          >
            {(formik) => (
              <form noValidate onSubmit={formik.handleSubmit}>
                {formik.errors.submit && (
                  <Alert mt={2} mb={1} severity="warning">
                    {formik.errors.submit}
                  </Alert>
                )}
                {!formik.errors.submit &&
                  formik.status?.success &&
                  showSuccess && (
                    <Alert mt={2} mb={1} severity="success">
                      Lançamento manual criado com sucesso.
                    </Alert>
                  )}
                <Grid container spacing={2}>
                  <Grid item xl={12} xs={12}>
                    <TextField
                      type="text"
                      name="company"
                      label="Empresa"
                      fullWidth
                      my={2}
                      select
                      error={Boolean(
                        formik.touched.company && formik.errors.company
                      )}
                      {...formik.getFieldProps("company")}
                    >
                      {companies.map((company) => (
                        <MenuItem key={company._id} value={company._id}>
                          {company.fantasyName}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xl={12} xs={12}>
                    <TextField
                      type="text"
                      name="period"
                      label="Competência"
                      fullWidth
                      my={2}
                      select
                      {...formik.getFieldProps("period")}
                    >
                      {periods.map((period) => (
                        <MenuItem value={period.value}>{period.text}</MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xl={12} xs={12}>
                    <TextField
                      type="text"
                      name="description"
                      label="Descrição (gerencial)"
                      fullWidth
                      my={2}
                      select
                      error={Boolean(
                        formik.touched.description && formik.errors.description
                      )}
                      {...formik.getFieldProps("description")}
                    >
                      <MenuItem value="banner">
                        Criação de materiais publicitários (banners e artes)
                      </MenuItem>
                      <MenuItem value="email-marketing">
                        Criação de materiais publicitários (email marketing)
                      </MenuItem>
                      <MenuItem value="social-media">
                        Criação de materiais publicitários (social media)
                      </MenuItem>
                      <MenuItem value="ads-setup">
                        Gestão de anúncios online (setup)
                      </MenuItem>
                      <MenuItem value="ads-churn">
                        Gestão de anúncios online (rescisão)
                      </MenuItem>
                      <MenuItem value="promotion">
                        Promoção de vendas por indicações
                      </MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xl={12} xs={12}>
                    <TextField
                      type="text"
                      name="serviceDescription"
                      label="Descrição personalizada (observação da NF)"
                      fullWidth
                      my={2}
                      {...formik.getFieldProps("serviceDescription")}
                    />
                  </Grid>
                  <Grid item xl={12} xs={12}>
                    <TextField
                      type="number"
                      name="value"
                      label="Valor (formato americano, sem divisão de milhar, decimal por ponto .)"
                      fullWidth
                      my={2}
                      error={Boolean(
                        formik.touched.value && formik.errors.value
                      )}
                      {...formik.getFieldProps("value")}
                    />
                  </Grid>
                </Grid>
                <CreateButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  Gravar
                </CreateButton>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default withTheme(withRouter(FinancialManualForm));
