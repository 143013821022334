import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import styled, { withTheme } from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { Formik } from "formik";
import { signIn, tokenValidate } from "../../redux/actions/authActions";
import BrandLogo from "../../components/BrandLogo";
import * as types from "../../constants";

import {
  Checkbox,
  FormControlLabel,
  Button,
  Paper,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

const BrandWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const BrandIcon = styled(BrandLogo)`
  height: 60px;
  text-align: center;
  margin: 0 auto ${(props) => props.theme.spacing(5)}px;
`;

const LoginButton = styled(Button)`
  padding: 10px 16px;
`;

function SignIn({ theme }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useSelector((state) => state.authReducer);

  const logout = () => {
    dispatch({
      type: types.AUTH_SIGN_OUT,
    });
  };

  if (auth.user && !auth.user?.requireToken) {
    history.push("/private");
  }

  return (
    <Wrapper>
      <Helmet title="Login" />
      <BrandWrapper>
        <BrandIcon
          isLight={theme.palette.type === "dark"}
          alt="Brand Up Logo"
        />
      </BrandWrapper>

      <>
        {auth.user?.requireToken ? (
          <>
            <Typography component="h2" variant="body1" align="center">
              Informe um token válido
            </Typography>
            <Formik
              initialValues={{
                submit: false,
              }}
              validationSchema={Yup.object().shape({
                token: Yup.string()
                  .min(6, "No mínimo 6 caracteres")
                  .max(6, "No máximo 6 caracteres"),
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
              ) => {
                try {
                  await dispatch(
                    tokenValidate({
                      token: values.token,
                      jwt: auth.user.jwt,
                    })
                  );
                  setErrors({ submit: "O token informado é inválido." });
                  // history.push("/private");
                } catch (error) {
                  const message = error.error || "Algo deu errado";

                  setStatus({ success: false });
                  setErrors({ submit: message });
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                  {errors.submit && (
                    <Alert mt={2} mb={1} severity="warning">
                      {errors.submit}
                    </Alert>
                  )}
                  <TextField
                    type="token"
                    name="token"
                    key="token"
                    label="Token"
                    value={values.token}
                    error={Boolean(touched.token && errors.token)}
                    fullWidth
                    helperText={touched.token && errors.token}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={2}
                  />
                  <LoginButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Entrar
                  </LoginButton>
                  <Button fullWidth color="primary" onClick={logout}>
                    Cancelar
                  </Button>
                </form>
              )}
            </Formik>
          </>
        ) : (
          <>
            <Typography component="h2" variant="body1" align="center">
              Informe seu e-mail e senha para continuar
            </Typography>
            <Formik
              initialValues={{
                submit: false,
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email("O email deve ser válido")
                  .max(255)
                  .required("O email é obrigatório"),
                password: Yup.string()
                  .max(255)
                  .required("A senha é obrigatória"),
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
              ) => {
                try {
                  await dispatch(
                    signIn({
                      email: values.email,
                      password: values.password,
                      remember: values.remember,
                    })
                  );
                  // history.push("/private");
                } catch (error) {
                  const message = error.error || "Algo deu errado";

                  setStatus({ success: false });
                  setErrors({ submit: message });
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                  {errors.submit && (
                    <Alert mt={2} mb={1} severity="warning">
                      {errors.submit}
                    </Alert>
                  )}
                  <TextField
                    type="email"
                    name="email"
                    label="Email"
                    key="email"
                    value={values.email}
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={2}
                  />
                  <TextField
                    type="password"
                    name="password"
                    label="Senha"
                    value={values.password}
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={2}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="remember"
                        value={values.remember}
                        onChange={handleChange}
                        color="primary"
                      />
                    }
                    label="Lembrar-me"
                  />
                  <LoginButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Entrar
                  </LoginButton>
                  <Button
                    component={Link}
                    to="/recuperar-senha"
                    fullWidth
                    color="primary"
                  >
                    Esqueci a senha
                  </Button>
                </form>
              )}
            </Formik>
          </>
        )}
        <Typography variant="body1" align="center">
          Seus dados estão seguros, sob a proteção da LGPD.
          <br />
          <Button component={Link} to="/termos" color="primary">
            Termos de Uso
          </Button>{" "}
          |{" "}
          <Button component={Link} to="/privacidade" color="primary">
            Políticas de Privacidade
          </Button>
        </Typography>
      </>
    </Wrapper>
  );
}

export default withTheme(SignIn);
