import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography as MuiTypography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Typography = styled(MuiTypography)(spacing);

function Changelog() {
  return (
    <React.Fragment>
      <Helmet title="Termos de Uso" />

      <Grid container spacing={6} justify="center">
        <Grid item xs={12}>
          <Typography variant="h2" gutterBottom display="inline">
            Termos de Uso
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/">
              Minha Brand
            </Link>
            <Typography>Termos de Uso</Typography>
          </Breadcrumbs>

          <Divider my={6} />

          <Box mt={3}>
            <Typography variant="subtitle1">
              <p>
                BRAND UP MARKETING DE PERFORMANCE EIRELI, inscrita no CNPJ sob o
                nº 28.905.603/0001-51, com sede à Rua Carlos Thiesen, 820 - Sala
                03, Centro - Ituporanga - SC, CEP 88400-000, única e exclusiva
                proprietária da plataforma disponível via website que utiliza a
                marca “MINHA BRAND”, estabelece o presente documento, denominado{" "}
                <b>TERMOS DE USO</b> para toda e qualquer pessoa física ou
                jurídica, que a seguir serão denominados como <b>USUÁRIO</b>.
              </p>
              <p>
                Nós, da equipe da MINHA BRAND, possuímos uma política de total
                transparência e respeito a você, assim, apresentamos abaixo
                nossos termos de uso, para que você entenda de forma detalhada o
                funcionamento da plataforma.
              </p>
              <p>
                É muito importante que você realize uma leitura atenta deste
                documento, pois ele poderá esclarecer diversas dúvidas a
                respeito de nossos serviços. Além disso,{" "}
                <b>
                  ao utilizar nossa plataforma, você declara sua aceitação a
                  todos os termos que estão aqui descritos
                </b>
                .
              </p>
              <p>Tenha uma boa leitura!</p>
            </Typography>

            <Divider my={6} />

            <Typography variant="h3">1. NOSSO SERVIÇO</Typography>

            <Typography variant="subtitle1">
              <p>
                A MINHA BRAND é um serviço que busca e tabula dados de sua loja
                virtual através de gráficos, de maneira online em tempo real.
              </p>
            </Typography>

            <Divider my={6} />

            <Typography variant="h3">2. DEFINIÇÕES</Typography>

            <Typography variant="subtitle1">
              <p>
                A contratação é realizada automaticamente por você, desde que
                aceite os termos de uso da MINHA BRAND. Os acessos aos serviços
                da plataforma vigorarão enquanto for cliente da Brand Up,
                enquanto agência de marketing.
              </p>
            </Typography>

            <Divider my={6} />

            <Typography variant="h3">3. ACESSO A PLATAFORMA</Typography>

            <Typography variant="subtitle1">
              <p>
                Para acessar a plataforma você deverá cadastrar uma senha de
                acesso segura, sendo tal informação pessoal e intransferível.
              </p>
              <p>
                <b>ATENÇÃO:</b> Você se compromete a não informar a terceiros a
                senha que cadastrou, responsabilizando-se integralmente pela sua
                segurança.
              </p>
              <p>
                A senha cadastrada é criptografada, ou seja, a MINHA BRAND não
                consegue identificá-la, assim, se você perder ou esquecer sua
                senha, deverá comunicar a MINHA BRAND sobre o ocorrido para que
                a senha antiga seja desabilitada e uma nova senha seja criada.
              </p>
              <p>
                Para acessar a sua conta na plataforma, basta informar o e-mail
                cadastrado e a senha.
              </p>
              <p>
                Uma vez coletados, trataremos seus dados de forma sigilosa,
                jamais revelando essas informações para pessoas que não
                estiverem diretamente envolvidas com os serviços da MINHA BRAND.
              </p>
            </Typography>

            <Divider my={6} />

            <Typography variant="h3">
              4. FUNCIONALIDADES DA MINHA BRAND
            </Typography>

            <Typography variant="subtitle1">
              <p>
                A MINHA BRAND coleta, automaticamente, as informações obtidas
                através de integração (API) com redes de parceiros de
                publicidade e plataformas de e-commerce, contudo é necessário o
                fornecimento de token e senha para isso. Com isso, são
                disponibilizadas informações relevantes de marketing em formas
                de gráficos.
              </p>
              <p>
                Apenas o gerente de contas que atende o cliente da plataforma
                MINHA BRAND, ou seja, os funcionários que atuam estritamente com
                as campanhas de marketing, conseguirão visualizar as informações
                de sua loja, que serão relevantes para ajustes em campanhas.
              </p>
              <p>
                Nenhum outro funcionário, representante ou parceiro da MINHA
                BRAND terá acesso às referidas informações.
              </p>
            </Typography>

            <Divider my={6} />

            <Typography variant="h3">5. COMUNICAÇÕES DA MINHA BRAND</Typography>

            <Typography variant="subtitle1">
              <p>
                As comunicações da MINHA BRAND com você sempre ocorrerão dentro
                da plataforma. Para que você fique por dentro de todas as nossas
                novidades é necessário acessar a tela “Atualizações”, disponível
                na coluna do lado esquerdo da tela.
              </p>
              <p>
                Caso você queira alterar algum dos dados informados no cadastro,
                basta acessar a tela “Minha conta”.
              </p>
            </Typography>

            <Divider my={6} />

            <Typography variant="h3">6. ENCERRAMENTO DA CONTA</Typography>

            <Typography variant="subtitle1">
              <p>
                Durante todo o período que você utilizar a MINHA BRAND estará
                sujeito a estes Termos de Uso. Após sua conta ser encerrada,
                seja por qual razão for, os Termos de Uso continuam valendo em
                relação aos atos que você praticou durante o tempo que utilizou
                a MINHA BRAND.
              </p>
              <p>
                Caso sejam identificadas contas de USUÁRIOS falsos/inexistentes,
                ou se por algum motivo acreditarmos que você não está
                respeitando as regras de nossos Termos de Uso, poderemos
                suspender ou excluir sua conta, sem qualquer aviso prévio. O
                fato de suspendermos ou excluirmos sua conta não significa que
                devemos pagar a você qualquer tipo de indenização.
              </p>
            </Typography>

            <Divider my={6} />

            <Typography variant="h3">7. LIMITAÇÃO DE USO</Typography>

            <Typography variant="subtitle1">
              <p>
                Os recursos disponibilizados pela MINHA BRAND poderão ser
                acessados pelos USUÁRIOS, contudo estes não poderão:
              </p>
              <ul>
                <li>
                  Realizar seu cadastro pessoal com informações falsas ou que
                  sejam de terceiros;
                </li>
                <li>
                  Alterar, apagar e/ou corromper dados e informações de
                  terceiros;
                </li>
                <li>
                  Realizar qualquer tipo de comercialização da marca MINHA
                  BRAND, bem como do conteúdo da plataforma, sem que haja o
                  consentimento expresso de seus representantes legais;
                </li>
                <li>
                  Violar a propriedade intelectual da MINHA BRAND, bem como de
                  terceiros em geral;
                </li>
                <li>
                  Realizar qualquer tipo de engenharia reversa ou técnicas
                  similares, para levantamento do software da plataforma, bem
                  como mineração de dados ou outras ferramentas similares que
                  possibilitem a coleta de dados obtidos por meio da MINHA
                  BRAND;
                </li>
              </ul>
              <p>
                Ocorrendo o descumprimento das condições estipuladas, a MINHA
                BRAND terá o direito de cancelar, suspender, excluir e/ou
                desativar o acesso e/ou o material enviado pelo USUÁRIO, por
                período temporário ou definitivamente, a seu único e exclusivo
                critério, sem prejuízo das medidas legais pertinentes.
              </p>
            </Typography>

            <Divider my={6} />

            <Typography variant="h3">8. ISENÇÃO DE RESPONSABILIDADE</Typography>

            <Typography variant="subtitle1">
              <p>
                Como somos uma plataforma digital, dependemos de algumas outras
                bases digitais para nosso funcionamento, como operadoras de luz,
                internet, entre outros. Além disso, frequentemente realizaremos
                ajustes técnicos para otimizar nossas funcionalidades, assim,
                eventualmente é possível que ocorram erros no funcionamento da
                MINHA BRAND.
              </p>
              <p>
                Tais erros serão prontamente analisados por nossa equipe,
                contudo, não temos como garantir um tempo preciso para que a
                questão seja solucionada. Por isso, é importante você entender
                que de vez em quando é possível que existam algumas
                instabilidades no site e não nos responsabilizamos por danos
                decorrentes da não disponibilidade ou erro de funcionamento da
                plataforma.
              </p>
            </Typography>

            <Divider my={6} />

            <Typography variant="h3">9. PROPRIEDADE INTELECTUAL</Typography>

            <Typography variant="subtitle1">
              <p>
                A BRAND UP MARKETING DE PERFORMANCE EIRELI é a única e legítima
                titular de direitos de propriedade intelectual, relativos à
                nossa marca e ao software, incluindo imagens ou qualquer outro
                material, conforme estabelece a lei da República Federativa do
                Brasil e demais leis internacionais de direitos autorais e
                propriedade industrial.
              </p>
              <p>
                Você respeitará nossa propriedade intelectual e as Leis de
                Propriedade Intelectual em vigor se não realizar cópias ou
                modificações de qualquer programação da nossa plataforma.
              </p>
              <p>
                A MINHA BRAND se reserva o direito, segundo exclusivo critério,
                de cancelar, suspender, excluir e/ou desativar o acesso e/ou o
                cadastro do USUÁRIO, caso haja indícios de violação destes
                direitos.
              </p>
            </Typography>

            <Divider my={6} />

            <Typography variant="h3">
              10. ATUALIZAÇÃO PERIÓDICA DO SISTEMA
            </Typography>

            <Typography variant="subtitle1">
              <p>
                A qualquer momento a MINHA BRAND poderá lançar uma nova versão
                de sua plataforma, corrigindo eventuais erros do sistema, e com
                modificações em relação à versão anterior, como adição ou
                remoção de funcionalidades previamente existentes. Tais
                atualizações poderão ser obrigatórias, ou seja, é possível que
                você não consiga mais utilizar a plataforma da MINHA BRAND
                enquanto não fizer a atualização solicitada.
              </p>
            </Typography>

            <Divider my={6} />

            <Typography variant="h3">
              11. ALTERAÇÃO DOS TERMOS DE USO
            </Typography>

            <Typography variant="subtitle1">
              <p>
                Como estamos sempre em busca de melhorias para nossos USUÁRIOS,
                é possível que futuramente existam mudanças em nossos serviços,
                o que poderá implicar em mudanças nos presentes Termos de Uso.
                Sempre que realizarmos algum tipo de alteração, avisaremos
                nossos USUÁRIOS e disponibilizaremos na plataforma a nova versão
                do documento.
              </p>
              <p>
                Além disso, recomendamos que de tempos em tempos os USUÁRIOS
                revejam os Termos de Uso da MINHA BRAND, uma vez que, ao
                realizar o acesso e utilizar a plataforma, automaticamente você
                aceita e subordina-se aos Termos de Uso então vigentes.
              </p>
            </Typography>

            <Divider my={6} />

            <Typography variant="h3">12. FORO</Typography>

            <Typography variant="subtitle1">
              <p>
                Fica eleito o foro da Comarca de Ituporanga, Estado de Santa
                Catarina, como competente para dirimir quaisquer controvérsias
                decorrentes destes Termos de Uso, independentemente de qualquer
                outro, por mais privilegiado que seja ou venha a ser.
              </p>
            </Typography>

            <Divider my={6} />
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Changelog;
