import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import AuthGuard from "./AuthGuard";

// For routes that can only be accessed by authenticated users
function AdminGuard({ children }) {
  const auth = useSelector((state) => state.authReducer);

  if (auth.user?.access !== 1) {
    return <Redirect to="/auth/404" />;
  }

  return <AuthGuard>{children}</AuthGuard>;
}

export default AdminGuard;
