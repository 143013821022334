import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { Helmet } from "react-helmet-async";

import { Typography as MuiTypography } from "@material-ui/core";

import { spacing } from "@material-ui/system";

import { Alert } from "@material-ui/lab";
import FinancialGrid from "../../common/FinancialGrid";
import axios from "../../../utils/axios";
import { LOGIN_URL } from "../../../constants/urls";

const Typography = styled(MuiTypography)(spacing);

function Default() {
  const { user } = useSelector((state) => state.authReducer);
  const [financialData, setFinancialData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  const loadAndSave = async () => {
    setLoading(true);
    const { jwt } = user;

    const response = await axios.get(`${LOGIN_URL}/finances`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });

    if (response.status === 200) {
      setFinancialData(response.data);
    } else {
      setError("Não foi possível carregar os dados");
    }
    setLoading(false);
  };

  useEffect(() => {
    loadAndSave();
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Financeiro" />
      <Typography variant="h3" gutterBottom>
        Financeiro
      </Typography>

      {loading && (
        <Alert mt={2} mb={1} severity="info">
          Carregando...
        </Alert>
      )}

      {!!error && (
        <Alert mt={2} mb={1} severity="warning">
          {error}
        </Alert>
      )}

      {!error && !loading && <FinancialGrid data={financialData} />}
    </React.Fragment>
  );
}

export default Default;
