import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Button,
  Paper,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";

import { updatePassword } from "../../services/authService";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);
const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

export default function NewPasword({ token }) {
  return (
    <Wrapper>
      <Helmet title="Criar Nova Senha" />

      <Typography component="h1" variant="h4" align="center" gutterBottom>
        Criar Nova Senha
      </Typography>
      <Typography component="h2" variant="body1" align="center">
        Informe a nova senha
      </Typography>

      <Formik
        initialValues={{
          password: "",
          validatePassword: "",
          submit: false,
        }}
        validationSchema={Yup.object().shape({
          password: Yup.string()
            .min(6, "No mínimo 6 caracteres")
            .max(255, "No máximo 255 caracteres")
            .required("A senha é obrigatória"),
          validatePassword: Yup.string().oneOf(
            [Yup.ref("password"), null],
            "As senhas devem ser iguais"
          ),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            const response = await updatePassword({
              password: values.password,
              token,
            });
            setStatus({
              success: true,
              message: response.message || "Requisição enviada",
            });
          } catch (error) {
            const message = error.message || "Algo deu errado";

            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          status,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert mt={2} mb={1} severity="warning">
                {errors.submit}
              </Alert>
            )}

            {status?.success && (
              <Alert mt={2} mb={1} severity="success">
                {status?.message}
              </Alert>
            )}
            <TextField
              type="password"
              name="password"
              label="Nova Senha"
              value={values.password}
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <TextField
              type="password"
              name="validatePassword"
              label="Repita a Nova Senha"
              value={values.validatePassword}
              error={Boolean(
                touched.validatePassword && errors.validatePassword
              )}
              fullWidth
              helperText={touched.validatePassword && errors.validatePassword}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
              Mudar Senha
            </Button>
            <Button component={Link} to="/" fullWidth color="primary">
              Login
            </Button>
          </form>
        )}
      </Formik>
    </Wrapper>
  );
}
