import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography as MuiTypography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

const Chip = styled(MuiChip)`
  height: 20px;
  margin-top: -3px;
  font-weight: bold;
  font-size: 75%;
`;

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Typography = styled(MuiTypography)(spacing);

function Changelog() {
  return (
    <React.Fragment>
      <Helmet title="Atualizações" />

      <Grid container spacing={6} justify="center">
        <Grid item xs={12} lg={9} xl={7}>
          <Typography variant="h2" gutterBottom display="inline">
            Atualizações
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/">
              Dashboard
            </Link>
            <Typography>Changelog</Typography>
          </Breadcrumbs>

          <Divider my={6} />

          <Box mt={3}>
            <Typography variant="subtitle1">
              <Chip color="secondary" label="v1.0.9" /> – 10 de Julho de 2024
              <ul>
                <li>Conclusão da integração com Plataforma Uoou.</li>
              </ul>
            </Typography>
          </Box>
          <Divider my={6} />

          <Box mt={3}>
            <Typography variant="subtitle1">
              <Chip color="secondary" label="v1.0.8" /> – 07 de Julho de 2023
              <ul>
                <li>Implementação de autenticação por 2 fatores.</li>
              </ul>
            </Typography>
          </Box>
          <Divider my={6} />

          <Box mt={3}>
            <Typography variant="subtitle1">
              <Chip color="secondary" label="v1.0.7" /> – 01 de Fevereiro de
              2023
              <ul>
                <li>Conclusão da integração com Planilhas Google.</li>
              </ul>
            </Typography>
          </Box>
          <Divider my={6} />

          <Box mt={3}>
            <Typography variant="subtitle1">
              <Chip color="secondary" label="v1.0.6" /> – 10 de Novembro de 2022
              <ul>
                <li>Conclusão do módulo "Financeiro".</li>
              </ul>
            </Typography>
          </Box>
          <Divider my={6} />

          <Box mt={3}>
            <Typography variant="subtitle1">
              <Chip color="secondary" label="v1.0.5" /> – 01 de Agosto de 2022
              <ul>
                <li>Inclusão do módulo "Financeiro".</li>
              </ul>
            </Typography>
          </Box>
          <Divider my={6} />

          <Box mt={3}>
            <Typography variant="subtitle1">
              <Chip color="secondary" label="v1.0.4" /> – 28 de Março de 2022
              <ul>
                <li>
                  Correção da leitura de investimentos de campanhas betha Google
                  Ads.
                </li>
              </ul>
            </Typography>
          </Box>
          <Divider my={6} />

          <Box mt={3}>
            <Typography variant="subtitle1">
              <Chip color="secondary" label="v1.0.3" /> – 23 de Janeiro de 2022
              <ul>
                <li>Adicionado novo filtro de Últimos 12 meses;</li>
                <li>Adicionado novo filtro Personalizado.</li>
              </ul>
            </Typography>
          </Box>
          <Divider my={6} />

          <Box mt={3}>
            <Typography variant="subtitle1">
              <Chip color="secondary" label="v1.0.2" /> – 23 de Outubro de 2021
              <ul>
                <li>
                  Correção na disposição da datas no dashboard "CAC (Custo de
                  Aquisição de Clientes)";
                </li>
                <li>
                  No filtro "Mês", os cálculos comparativos ao mês anterior
                  passam a comparar os mesmos períodos de datas entre o mês
                  atual e mês anterior.
                </li>
              </ul>
            </Typography>
            <Divider my={6} />

            <Typography variant="subtitle1">
              <Chip color="secondary" label="v1.0.1" /> – 31 de Agosto de 2021
              <ul>
                <li>
                  Inclusão de parcial de boletos pendentes. Feito o cálculo
                  percentual de aprovação dos últimos 15 dias;
                </li>
                <li>Correção do bug nos filtros de períodos;</li>
                <li>
                  Correção da importação de dados do Google Ads no filtro
                  "Hoje";
                </li>
                <li>
                  Inclusão de múltiplas lojas que operam no mesmo ambiente.
                </li>
              </ul>
            </Typography>
            <Divider my={6} />

            <Typography variant="subtitle1">
              <Chip color="secondary" label="v1.0.0" /> – 31 de Julho de 2021
              <ul>
                <li>Dashboard de KPIs de Marketing.</li>
              </ul>
            </Typography>
            <Divider my={6} />
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Changelog;
