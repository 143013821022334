import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";

import {
  List,
  ListItem,
  ListItemText as MuiListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import { setShop } from "../redux/actions/authActions";

const ListItemText = styled(MuiListItemText)(spacing);

function ShopDropdown() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authReducer);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    if (!user) return;
    let companiesSorted = user.companies.sort((company1, company2) =>
      company1.fantasyName.localeCompare(company2.fantasyName)
    );
    companiesSorted = companiesSorted.filter((company) => {
      return company.hasOwnProperty("active") ? company.active : true;
    });

    setCompanies(companiesSorted);
    if (user.selectedCompany.id) {
      setSelectedIndex(
        companiesSorted.findIndex(({ id }) => id === user.selectedCompany.id)
      );
    } else {
      setSelectedIndex(
        companiesSorted.findIndex(({ _id }) => _id === user.selectedCompany._id)
      );
    }
  }, [user]);

  if (!user) {
    return <Redirect to="/login" />;
  }

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = async (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    await dispatch(setShop(companies[index]));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!companies || companies.length === 0) {
    return <Typography>Sem Empresa</Typography>;
  }

  return (
    <React.Fragment>
      <List component="nav" aria-label="Device settings">
        <ListItem
          button
          disabled={companies.length < 2}
          aria-haspopup="true"
          aria-controls="lock-menu"
          aria-label={companies[selectedIndex]?.fantasyName}
          onClick={handleClickListItem}
        >
          <ListItemText primary={companies[selectedIndex]?.fantasyName} />
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {companies.map((option, index) => (
          <MenuItem
            key={option.fantasyName}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            {option.fantasyName}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
}

export default ShopDropdown;
