import React from "react";
import { useSelector } from "react-redux";
import styled, { withTheme } from "styled-components/macro";

import { orange, green, red, grey } from "@material-ui/core/colors";

import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow as MuiTableRow,
  Typography,
  Chip as MuiChip,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import { Doughnut } from "react-chartjs-2";
import { fontSizes } from "../../../../constants/designSystem";

const Card = styled(MuiCard)(spacing);

const ChartTableWrapper = styled.div`
  width: 100%;
`;

const ChartWrapper = styled.div`
  height: 168px;
  position: relative;
`;

const DoughnutInner = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -22px;
  text-align: center;
  z-index: 0;
`;

const TableRow = styled(MuiTableRow)`
  height: 42px;
`;

const TableCell = styled(MuiTableCell)`
  padding-top: 0;
  padding-bottom: 0;
`;

const GreenText = styled.span`
  color: ${() => green[400]};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const RedText = styled.span`
  color: ${() => red[400]};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const GreyText = styled.span`
  color: ${() => grey[400]};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const Chip = styled(MuiChip)`
  height: 20px;
  font-size: 85%;
  background-color: ${(props) => props.theme.palette.secondary.main};
  color: ${(props) => props.theme.palette.common.white};

  span {
    padding-left: ${(props) => props.theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;
  }
`;

const currency = Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

const percent = Intl.NumberFormat("pt-BR", {
  style: "percent",
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

const comparePeriods = (lastPeriod, thisPeriod) => {
  let numericValue = (thisPeriod - lastPeriod) / lastPeriod;

  if (typeof numericValue !== "number" || !isFinite(numericValue)) {
    return {
      formatted: "-%",
      ColorText: GreyText,
    };
  }

  const plus = numericValue > 0 ? "+" : "";
  return {
    formatted: plus + percent.format(numericValue),
    ColorText: numericValue > 0 ? GreenText : RedText,
  };
};

const GenericDashTable = ({ title, data, columns, attributes }) => {
  const { loadPeriod } = useSelector((state) => state.periodReducer);

  const { tag } = loadPeriod();

  return (
    <Card mb={3}>
      <CardHeader action={<Chip label={tag} />} title={title} />

      <CardContent>
        <ChartTableWrapper>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column, index) => {
                  return (
                    <TableCell
                      key={column}
                      align={index === 0 ? "left" : "right"}
                    >
                      {column}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item) => {
                return (
                  <TableRow key={item[attributes[0]]}>
                    {attributes.map((attribute, index) => {
                      return (
                        <TableCell
                          key={attribute}
                          align={index === 0 ? "left" : "right"}
                        >
                          {item[attribute]}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </ChartTableWrapper>
      </CardContent>
    </Card>
  );
};

export default withTheme(GenericDashTable);
