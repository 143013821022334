import React from "react";
import { format, compareAsc } from "date-fns";
import styled, { withTheme } from "styled-components/macro";

import { Card as MuiCard, CardContent, CardHeader } from "@material-ui/core";

import { spacing } from "@material-ui/system";

import "../../../../vendor/roundedBarCharts";
import { Bar } from "react-chartjs-2";
import { fontSizes } from "../../../../constants/designSystem";

const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
  height: 378px;
  width: 100%;
`;

const currency = Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

const percent = Intl.NumberFormat("pt-BR", {
  style: "percent",
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

const Cac = ({ theme, cacData }) => {
  const firstDatasetColor = theme.palette.secondary.main;
  const secondDatasetColor =
    theme.palette.type === "dark"
      ? "rgba(255, 255, 255, 0.5)"
      : "rgba(0, 0, 0, 0.15)";

  const titles = ["Investimento em mídia", "Vendas Aprovadas"];

  cacData.sort((day1, day2) =>
    compareAsc(new Date(day1.date), new Date(day2.date))
  );

  const { labels, investments, approvedSales } = cacData.reduce(
    (acc, day) => {
      acc.labels.push(format(new Date(day.date), "dd/MM/yyyy"));
      acc.investments.push(day.paidMediaInvestment);
      acc.approvedSales.push(day.approvedSales);

      return acc;
    },
    { labels: [], investments: [], approvedSales: [] }
  );

  const data = {
    labels: labels,
    datasets: [
      {
        label: titles[0],
        backgroundColor: firstDatasetColor,
        borderColor: firstDatasetColor,
        hoverBackgroundColor: firstDatasetColor,
        hoverBorderColor: firstDatasetColor,
        data: investments,
        barPercentage: 0.5,
        categoryPercentage: 0.5,
      },
      {
        label: titles[1],
        backgroundColor: secondDatasetColor,
        borderColor: secondDatasetColor,
        hoverBackgroundColor: secondDatasetColor,
        hoverBorderColor: secondDatasetColor,
        data: approvedSales,
        barPercentage: 0.5,
        categoryPercentage: 0.5,
      },
    ],
  };

  const label = (tooltip) => {
    return (
      titles[tooltip.datasetIndex] + ": " + currency.format(tooltip.yLabel)
    );
  };

  const footer = (tooltipItems) => {
    let sum = "-";
    try {
      sum = tooltipItems[0].yLabel / tooltipItems[1].yLabel;
      sum = percent.format(sum);
    } catch (e) {
      // Missing value
    }
    return "CAC: " + sum;
  };

  const options = {
    maintainAspectRatio: false,
    cornerRadius: 2,
    legend: {
      display: false,
    },
    tooltips: {
      mode: "index",
      callbacks: {
        label,
        footer,
      },
      titleFontSize: fontSizes.normal,
      bodyFontSize: fontSizes.medium,
      footerFontSize: fontSizes.medium,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
            callback: (value) => currency.format(value),
          },
        },
      ],
      xAxes: [
        {
          stacked: true,
          gridLines: {
            color: "transparent",
          },
          ticks: {
            fontColor: theme.palette.text.secondary,
          },
        },
      ],
    },
  };

  return (
    <Card mb={1}>
      <CardHeader title="CAC (Custo de Aquisição de Clientes)" />

      <CardContent>
        <ChartWrapper>
          <Bar data={data} options={options} />
        </ChartWrapper>
      </CardContent>
    </Card>
  );
};

export default withTheme(Cac);
