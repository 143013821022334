import axios from "../utils/axios";
import { LOGIN_URL } from "../constants/urls";

export function signIn(credentials) {
  return new Promise((resolve, reject) => {
    axios
      .post(LOGIN_URL + "/login", credentials)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function otpValidate({ token, jwt }) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        LOGIN_URL + "/otp-validate",
        { token },
        { headers: { Authorization: `Bearer ${jwt}` } }
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function signUp(credentials) {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/auth/sign-up", credentials)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function resetPassword(credentials) {
  return new Promise((resolve, reject) => {
    axios
      .post(LOGIN_URL + "/forgot-password", credentials)
      .then((response) => {
        if (response.status === 200 && !response.data.error) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function updatePassword({ password, token }) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        LOGIN_URL + "/update-password",
        { password },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
          validateStatus: function (status) {
            return true;
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && !response.data.error) {
          resolve(response.data);
        }
        reject({ message: response.data.error });
      })
      .catch((error) => {
        reject(error);
      });
  });
}
