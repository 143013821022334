import React from "react";
import styled from "styled-components/macro";

import {
  Box,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Chip as MuiChip,
  Typography as MuiTypography,
  Tooltip,
} from "@material-ui/core";

import { rgba } from "polished";

import { spacing } from "@material-ui/system";
import { useSelector } from "react-redux";

const Card = styled(MuiCard)(spacing);

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`;

const Chip = styled(MuiChip)`
  position: absolute;
  top: 16px;
  right: 16px;
  height: 20px;
  padding: 4px 0;
  font-size: 85%;
  background-color: ${(props) => props.theme.palette.secondary.main};
  color: ${(props) => props.theme.palette.common.white};
  margin-bottom: ${(props) => props.theme.spacing(4)}px;

  span {
    padding-left: ${(props) => props.theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;
  }
`;

const Percentage = styled(MuiTypography)`
  span {
    color: ${(props) => props.percentagecolor};
    font-weight: ${(props) => props.theme.typography.fontWeightBold};
    background: ${(props) => rgba(props.percentagecolor, 0.1)};
    padding: 2px;
    border-radius: 3px;
    margin-right: ${(props) => props.theme.spacing(2)}px;
  }
`;

const Stats = ({
  title,
  amount,
  chip,
  percentageText,
  percentagecolor,
  tooltipText,
  amountWithOpenBoletos,
  percentageWithOpenBoletos,
}) => {
  const { loadPeriod } = useSelector((state) => state.periodReducer);
  const [tooltipOpen, setTooptipOpen] = React.useState(false);

  let timeout;

  const { legend } = loadPeriod();

  const TooltipOpen = tooltipText
    ? (props) => <Tooltip {...props} />
    : ({ children }) => children;

  const handleTooltipClose = () => {
    clearTimeout(timeout);
    setTooptipOpen(false);
  };

  const handleTooltipOpen = () => {
    clearTimeout(timeout);
    setTooptipOpen(true);
    timeout = setTimeout(handleTooltipClose, 6000);
  };

  return (
    <Card mb={3}>
      <CardContent>
        <Typography variant="h6" mb={4}>
          {title}
        </Typography>
        <TooltipOpen
          title={<Typography variant="body1">{tooltipText}</Typography>}
          placement="top-start"
          interactive
          open={tooltipOpen}
        >
          <Typography variant="h3" mb={3}>
            <Box fontWeight="fontWeightRegular" onClick={handleTooltipOpen}>
              {amount}
              {tooltipText ? "*" : ""}
            </Box>
          </Typography>
        </TooltipOpen>
        {!!percentageText && (
          <Percentage
            variant="subtitle2"
            mb={4}
            color="textSecondary"
            percentagecolor={percentagecolor}
          >
            <span>{percentageText}</span> {legend}
          </Percentage>
        )}
        <Chip label={chip} />
      </CardContent>
    </Card>
  );
};

export default Stats;
