import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";

import { Helmet } from "react-helmet-async";

import { Formik } from "formik";

import {
  Box,
  Divider as MuiDivider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography as MuiTypography,
  IconButton,
  TextField,
  MenuItem,
} from "@material-ui/core";

import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  CloudDownload as DownloadIcon,
} from "@material-ui/icons";

import { spacing } from "@material-ui/system";
import axios from "../../../utils/axios";
import { LOGIN_URL } from "../../../constants/urls";
import { Alert } from "@material-ui/lab";

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

const OverflowXGrid = styled(Grid)`
  overflow-x: scroll;
`;

function Companies() {
  const history = useHistory();
  const [companies, setCompanies] = useState([]);
  const [loading, isLoading] = useState(true);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const {
    user: { jwt },
  } = useSelector((state) => state.authReducer);

  const loadList = () => {
    isLoading(true);
    setCompanies([]);
    setSuccess(false);
    setError(false);
    axios
      .get(LOGIN_URL + "/companies", {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
      .then((response) => {
        isLoading(false);
        if (response.status === 200) {
          return setCompanies(response.data);
        }
        if (response.data.error) {
          setError(response.data.error);
        } else {
          setError("Algo deu errado");
        }
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  useEffect(loadList, []);

  const editCompany = (company) => {
    history.push({
      pathname: "/admin/companies/edit",
      state: {
        company,
      },
    });
  };

  const deleteCompany = (id) => {
    setSuccess(false);
    setError(false);
    axios
      .delete(LOGIN_URL + "/companies/delete", {
        data: {
          id,
        },
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          loadList();
          return setSuccess("Empresa deletada com sucesso");
        }
        if (response.data.error) {
          setError(response.data.error);
        } else {
          setError("Algo deu errado");
        }
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const loadCompany = (id) => {
    setSuccess(false);
    setError(false);
    axios
      .post(
        LOGIN_URL + "/companies/load",
        {
          id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
          validateStatus: (status) => {
            return true;
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          return setSuccess(response.data.message);
        }
        if (response.data.error) {
          setError(response.data.error);
        } else {
          setError("Algo deu errado");
        }
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const isRowFiltered = (company, filters) => {
    if (filters.active && (filters.active == "1") !== company.active) {
      return false;
    }
    if (
      filters.fantasyName &&
      !company.fantasyName
        .toLowerCase()
        .replace(" ", "")
        .includes(filters.fantasyName.toLowerCase().replace(" ", ""))
    ) {
      return false;
    }
    return true;
  };

  return (
    <React.Fragment>
      <Helmet title="Empresas" />

      <OverflowXGrid container spacing={6}>
        <Grid item lg={12}>
          <Typography variant="h2" gutterBottom display="inline">
            Empresas
          </Typography>

          <Divider my={6} />

          {!!error && (
            <Alert mt={2} mb={1} severity="warning">
              {error}
            </Alert>
          )}

          {!!success && (
            <Alert mt={2} mb={1} severity="success">
              {success}
            </Alert>
          )}

          {loading && (
            <Typography gutterBottom display="inline">
              Carregando lista...
            </Typography>
          )}

          {!loading && (
            <Formik
              initialValues={{
                companies: companies,
              }}
            >
              {(formik) => (
                <form>
                  <TextField
                    type="text"
                    name="fantasyName"
                    label="Nome fantasia"
                    fullWidth
                    my={2}
                    {...formik.getFieldProps("fantasyName")}
                  />
                  <TextField
                    type="text"
                    name="active"
                    label="Ativo"
                    fullWidth
                    my={2}
                    select
                    {...formik.getFieldProps("active")}
                  >
                    <MenuItem>Remove filtro</MenuItem>
                    <MenuItem value="1">Sim</MenuItem>
                    <MenuItem value="0">Não</MenuItem>
                  </TextField>
                  <Divider my={6} />

                  <Box mt={3}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>CNPJ</TableCell>
                          <TableCell>Razão Social</TableCell>
                          <TableCell>Nome Fantasia</TableCell>
                          <TableCell align="right">Ações</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {companies.map((company) => {
                          if (isRowFiltered(company, formik.values)) {
                            return (
                              <TableRow key={company._id}>
                                <TableCell component="th" scope="user">
                                  {company.cnpj}
                                </TableCell>
                                <TableCell>{company.companyName}</TableCell>
                                <TableCell>{company.fantasyName}</TableCell>
                                <TableCell align="right">
                                  <IconButton
                                    mx={2}
                                    aria-label="Load"
                                    onClick={() => loadCompany(company._id)}
                                  >
                                    <DownloadIcon fontSize="small" />
                                  </IconButton>
                                  <IconButton
                                    mx={2}
                                    aria-label="Edit"
                                    onClick={() => editCompany(company)}
                                  >
                                    <EditIcon fontSize="small" />
                                  </IconButton>
                                  <IconButton
                                    mx={2}
                                    aria-label="Delete"
                                    onClick={() => deleteCompany(company._id)}
                                  >
                                    <DeleteIcon fontSize="small" />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            );
                          }
                        })}
                      </TableBody>
                    </Table>
                  </Box>
                </form>
              )}
            </Formik>
          )}
        </Grid>
      </OverflowXGrid>
    </React.Fragment>
  );
}

export default Companies;
