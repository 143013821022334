import _ from "lodash";

import axios from "../../../../utils/axios";
import { DASHBOARD_URL } from "../../../../constants/urls";

const loadResource = async (jwt, id, params = {}) => {
  const response = await axios.get(
    `${DASHBOARD_URL}/dashboards/generalById/${id}`,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      params,
    }
  );

  if (response.status === 200 && !_.isEmpty(response.data)) {
    return response.data;
  } else {
    throw new Error();
  }
};

export default async function loadData(
  { jwt, selectedCompany, access },
  period
) {
  try {
    const currentPeriod = {
      fromDate: period.fromDate,
      toDate: period.toDate,
      withOpenBoletos: period.predict,
      openBoletoInterval: period.timeInterval,
      compareFromDate: period.compareInterval.fromDate,
      compareToDate: period.compareInterval.toDate,
    };
    const id = selectedCompany?.id ?? selectedCompany?._id;

    const dashboard = await loadResource(jwt, id, currentPeriod);

    return dashboard;
  } catch (error) {
    return false;
  }
}
