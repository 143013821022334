/* eslint-disable import/first */
import React from "react";

import async from "../components/Async";

import {
  BookOpen,
  List,
  Sliders,
  Users as UserIcon,
  Briefcase,
  User,
  CreditCard,
} from "react-feather";

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Guards
import AuthGuard from "../components/AuthGuard";
import AdminGuard from "../components/AdminGuard";

// Auth components
import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
import ResetPassword from "../pages/auth/ResetPassword";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";

// Dashboards components
import MarketingDashboard from "../pages/user/dashboards/marketing/MarketingDashboard";
import DemographicDashboard from "../pages/user/dashboards/demographic/DemographicDashboard";
import GMVDashboard from "../pages/user/dashboards/gmv/GMVDashboard";
import UserFinances from "../pages/user/Financial";
import MyAccount from "../pages/user/MyAccount";
import UseTerms from "../pages/docs/UseTerms";
import PrivacityPolicy from "../pages/docs/PrivacityPolicy";
import Changelog from "../pages/user/Changelog";

// Landing
import Landing from "../pages/presentation/Landing";

// Protected routes
import ProtectedPage from "../pages/protected/ProtectedPage";

// Admin
import Users from "../pages/admin/Users";
import CreateUser from "../pages/admin/Users/Form";

import Companies from "../pages/admin/Companies";
import CreateCompany from "../pages/admin/Companies/Form";

import FinancialForm from "../pages/admin/Financial/invoice";
import FinancialManualForm from "../pages/admin/Financial/manual";
import AdminFinancial from "../pages/admin/Financial";

const dashboardsRoutes = {
  id: "Dashboard",
  path: "/dashboard",
  header: "Minha Brand",
  icon: <Sliders />,
  containsHome: true,
  children: [
    {
      path: "/dashboard/marketing",
      name: "Marketing",
      component: MarketingDashboard,
      guard: AuthGuard,
    },
    // {
    //   path: "/dashboard/demographic",
    //   name: "Demográfico",
    //   component: DemographicDashboard,
    //   guard: AuthGuard,
    // },
  ],
  component: null,
  guard: AuthGuard,
  feature: "dashboard",
};

const adminDashboardRoutes = {
  ...dashboardsRoutes,
  children: [
    ...dashboardsRoutes.children,
    {
      path: "/dashboard/gmv",
      name: "GMV",
      component: GMVDashboard,
      guard: AdminGuard,
    },
  ],
};

const clientFinancialRoutes = {
  id: "Financeiro",
  path: "/financial/customers",
  icon: <CreditCard />,
  component: UserFinances,
  children: null,
  guard: AuthGuard,
  feature: "financial",
};

const accountRoutes = {
  id: "Minha Conta",
  path: "/minha-conta",
  header: "Informações da Conta",
  icon: <BookOpen />,
  containsHome: true,
  children: [
    {
      path: "/minha-conta/perfil",
      name: "Perfil",
      component: MyAccount,
      guard: AuthGuard,
    },
  ],
  component: null,
  guard: AuthGuard,
};

const changelogRoutes = {
  id: "Atualizações",
  path: "/changelog",
  badge: "v1.0.9",
  icon: <List />,
  component: Changelog,
  children: null,
  guard: AuthGuard,
};

// This route is only visible while signed in
const protectedPageRoutes = {
  id: "Private",
  path: "/private",
  component: ProtectedPage,
  guard: AuthGuard,
  children: [
    {
      path: "/private",
      name: "Private",
      component: ProtectedPage,
      guard: AuthGuard,
    },
    {
      path: "/admin/companies/edit",
      name: "Edit",
      component: CreateCompany,
      guard: AdminGuard,
    },
    {
      path: "/admin/users/edit",
      name: "Edit",
      component: CreateUser,
      guard: AdminGuard,
    },
    {
      path: "/private/termos",
      name: "Termos de Uso",
      component: UseTerms,
      guard: AuthGuard,
    },
    {
      path: "/private/privacidade",
      name: "Política de Privacidade",
      component: PrivacityPolicy,
      guard: AuthGuard,
    },
  ],
};

const userRoutes = {
  id: "Usuários",
  path: "/admin/users",
  header: "Admin",
  icon: <User />,
  containsHome: true,
  children: [
    {
      path: "/admin/users/all",
      name: "Todos",
      component: Users,
      guard: AdminGuard,
    },
    {
      path: "/admin/users/form",
      name: "Criar",
      component: CreateUser,
      guard: AdminGuard,
    },
  ],
  component: null,
  guard: AdminGuard,
};

const companyRoutes = {
  id: "Empresas",
  path: "/admin/companies",
  icon: <Briefcase />,
  containsHome: true,
  children: [
    {
      path: "/admin/companies/all",
      name: "Todos",
      component: Companies,
      guard: AdminGuard,
    },
    {
      path: "/admin/companies/form",
      name: "Criar",
      component: CreateCompany,
      guard: AdminGuard,
    },
  ],
  component: null,
  guard: AdminGuard,
};

const financialRoutes = {
  id: "Financeiro",
  path: "/admin/financial",
  icon: <CreditCard />,
  containsHome: true,
  children: [
    {
      path: "/admin/financial/all",
      name: "Todos",
      component: AdminFinancial,
      guard: AdminGuard,
    },
    {
      path: "/admin/financial/invoice",
      name: "Criar",
      component: FinancialForm,
      guard: AdminGuard,
    },
    {
      path: "/admin/financial/form",
      name: "Lançar Manual",
      component: FinancialManualForm,
      guard: AdminGuard,
    },
  ],
  component: null,
  guard: AdminGuard,
};

const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <UserIcon />,
  children: [
    {
      path: "/login",
      name: "Sign In",
      component: SignIn,
    },
    {
      path: "/auth/sign-up",
      name: "Sign Up",
      component: SignUp,
    },
    {
      path: "/recuperar-senha",
      name: "Reset Password",
      component: ResetPassword,
    },
    {
      path: "/recuperar-senha/:token",
      name: "Reset Password",
      component: ResetPassword,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
  ],
  component: null,
};

const landingRoutes = {
  id: "Landing Page",
  path: "/",
  component: Landing,
  children: null,
};

const termsRoutes = {
  id: "Termos",
  children: [
    {
      path: "/termos",
      name: "Termos de Uso",
      component: UseTerms,
    },
    {
      path: "/privacidade",
      name: "Política de Privacidade",
      component: PrivacityPolicy,
    },
  ],
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  adminDashboardRoutes,
  clientFinancialRoutes,
  accountRoutes,
  changelogRoutes,
  userRoutes,
  companyRoutes,
  financialRoutes,
];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes using the Terms layout
export const termsLayoutRoutes = [termsRoutes];

// Routes using the Presentation layout
export const presentationLayoutRoutes = [landingRoutes];

// Routes that are protected
export const protectedRoutes = [protectedPageRoutes];

export const sidebarRoutes = [
  dashboardsRoutes,
  clientFinancialRoutes,
  accountRoutes,
  changelogRoutes,
];

export const adminSidebarRoutes = [
  adminDashboardRoutes,
  clientFinancialRoutes,
  accountRoutes,
  changelogRoutes,
  userRoutes,
  companyRoutes,
  financialRoutes,
];
