import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { signOut, updateUser } from "../redux/actions/authActions";

import { Redirect } from "react-router-dom";

import _ from "lodash";

import { LOGIN_URL } from "../constants/urls";
import axios from "../utils/axios";
import { setPeriod } from "../redux/actions/periodActions";
import { initialState } from "../redux/reducers/periodReducer";

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }) {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.authReducer);
  const [loading, setLoading] = useState(true);
  const [tokenValid, isTokenValid] = useState(true);

  useEffect(() => {
    validateToken();
  }, []);

  if (!auth.user) {
    return <Redirect to="/login" />;
  }

  const validateToken = async () => {
    const response = await axios.post(
      LOGIN_URL + "/validate-token",
      {},
      { headers: { Authorization: `Bearer ${auth.user.jwt}` } }
    );

    if (response.status !== 200) {
      if (response.data?.requireToken) {
      } else {
        isTokenValid(false);
        dispatch(signOut());
      }
    } else {
      const newUser = {
        jwt: response.data.jwt,
        name: response.data.name,
        email: response.data.email,
        companies: response.data.companies,
        access: response.data.access,
        selectedCompany: response.data.companies[0] || null,
        permissions: response.data.permissions,
        hasSecret: response.data.hasSecret,
      };
      const hasChanged = !_.isEqual(auth.user, newUser);
      if (hasChanged) {
        dispatch(updateUser(newUser));
      }
    }
    dispatch(setPeriod(initialState.loadPeriod));
    setLoading(false);
  };

  if (loading || !tokenValid) {
    return <div />;
  }

  return children;
}

export default AuthGuard;
